<template>
    <!-- Список справочников -->    
    <div class="container-page">
        <title-page-component>
            <template v-slot:title>
                {{ dictionaryName }}
            </template>
            <template v-slot:button>
              <takeout-layout takeout="right">
                <button-component class="upload-button" @click="isUploadOpen=true"
                  >Загрузить
                  <template #icon-left>
                    <img src="@/assets/images/svg/button-unload.svg" alt="" style="transform:rotate(180deg);" />
                  </template>
                </button-component>
              </takeout-layout>
            </template>
        </title-page-component>
        <nsi-table
            @edit="openModalEdit"
            @create="openModalCreate"
            @delete="deleteItem"
            @upload-register="uploadItems"
            @sort="sort"
            @search="search"
            @pageChange="pageChange"
            :total-count="totalCount"
            :current-count="currentCount"
            :register="items"
            :register-title="itemsColumn"
            :is-register="'kit'"
            :is-loading="isLoading"
        >
        </nsi-table>
        <div class="modals">
            <modal-component
              @close-modal="this.isCreateOpen = !this.isCreateOpen"
              v-if="isCreateOpen"
            >
              <template v-slot:title>Добавление записи</template>
              <template v-slot:content>
                <div class="modal-register" @click.stop>
                  <takeout-layout>
                    <input-field
                      class="input-component"
                      v-model:value="newItem.title"
                      label="Наименование"
                      :label-error="validatedForm.title"
                      type="text"
                      max-length="100"
                    />
                  </takeout-layout>
                  <!-- <takeout-layout>
                    <input-field
                      class="input-component"
                      v-model:value="newItem.code"
                      label="Код"
                      :label-error="validatedForm.code"
                      type="text"
                      max-length="50"
                    />
                  </takeout-layout> -->
                  <takeout-layout>
                    <button-component @click="createItem">
                      Сохранить
                    </button-component>
                  </takeout-layout>
                </div>
              </template>
            </modal-component>
            <modal-component
              @close-modal="this.isEditOpen = !this.isEditOpen"
              v-if="isEditOpen"
            >
              <template v-slot:title>Редактирование записи</template>
              <template v-slot:content>
                <div class="modal-register" @click.stop>
                  <takeout-layout>
                    <input-field
                      class="input-component"
                      v-model:value="editItem.title"
                      label="Наименование"
                      :label-error="validatedForm.title"
                      type="text"
                      max-length="100"
                    />
                  </takeout-layout>
                  <!-- <takeout-layout>
                    <input-field
                      class="input-component"
                      v-model:value="editItem.code"
                      label="Код"
                      :label-error="validatedForm.code"
                      type="text"
                      max-length="50"
                    />
                  </takeout-layout> -->
                  <takeout-layout>
                    <button-component @click="updateItem">
                      Сохранить
                      <template #icon-left>
                        <img src="@/assets/images/svg/button-add.svg" alt="" />
                      </template>
                    </button-component>
                  </takeout-layout>
                </div>
              </template>
            </modal-component>
            <modal-component @close-modal="this.isUploadOpen = !this.isUploadOpen"
              v-if="isUploadOpen">
              <template v-slot:title>Загрузка записей</template>
              <template v-slot:content>
                <div class="modal-register" @click.stop>
                  <takeout-layout>
                    <a href="javascript:" @click="getTemplate">Скачать шаблон</a>
                  </takeout-layout>
                  <takeout-layout>
                    <div>
                      <label v-if="fileError" class="input__label input__label_error">{{fileError}}</label>
                      <input ref="fileInput" type="file" name="file" id="file-input" :accept="acceptFileTypes" @change="fileChanged($event)" />
                    </div>                    
                  </takeout-layout>
                  <takeout-layout>
                    <button-component @click="uploadFile">
                      Загрузить
                    </button-component>
                  </takeout-layout>
                </div>
              </template>
            </modal-component>
        </div>
    </div>
</template>
<script>
import TitlePageComponent from "@/components/ui/TitlePageComponent.vue";
import NsiTable from "@/modules/dictionaries/helpers/NsiTable.vue";
import InputField from "@/components/ui/InputField.vue";
import ModalComponent from "@/components/ui/ModalComponent.vue";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import axios from "axios";
import { checkAllFieldsEmpty, checkFieldEmpty } from "@/helpers/validate";
import Constants from '@/common/constants';
import utils from "@/common/utils";

export default {
  name: "nsiBase",
  components: {
    TitlePageComponent,
    NsiTable,
    InputField,
    ModalComponent,
    ButtonComponent,
    TakeoutLayout
  },
  props: {
    dictionaryName: String,
    dictionaryApiName: String,
  },
  data() {
    return {
        isLoading: false,
        page: 1,
        totalCount: null,
        currentCount: null,
        searchValue: "",
        titleSort: "",
        directionSort: null,
        items: [],
        itemsColumn: [
            {
                code: "title",
                title: "Наименование",
                width: "80%",
            },
            // {
            //     code: "code",
            //     title: "Код",
            //     width: "30%",
            // },
        ],

        isUploadOpen: false,
        isCreateOpen: false,
        isEditOpen: false,
        newItem: {
          title: null,
          //code: null
        },
        editItem: {
          id: null,
          title: null,
          //code: null
        },
        validatedForm: {
          title: "",
          //code: ""
        },

        acceptFileTypes: "xlsx",
        fileError: "",
        file: null,
    };
  },
  methods: {
    async getItems() {
      this.isLoading = true;
      this.loadItems(false);
    },
    async uploadItems() {
      this.loadItems(true);
    },
    async loadItems(isAddition) {
      this.page = isAddition ? this.page + 1 : 1;
      try {
        const response = await axios.post(
          "nsi/" + this.dictionaryApiName + "/search",
          this.requestParams,
        );
        const { totalCount, data, count } = {
            totalCount: response.data.totalCount,
            data: response.data.items,
            count: response.data.items.length,
        };
        this.totalCount = totalCount;
        if(isAddition) {
          this.items.push(... data);
        } else {
          this.items = data;
        }        
        this.currentCount = isAddition ? this.currentCount + count : count;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteItem(id) {
      Constants.alert.fire(Constants.confirmOptions.delete).then((res) => {
          if (res.isConfirmed) {
            axios.delete("nsi/" + this.dictionaryApiName + "/" + id)
            .then(() => {
              this.getItems();
            })
            .catch(() => {
                Constants.alert.fire('Удаление', 'Ошибка при удалении записи справочника. Она используется в других сущностях.', 'error');
              });
          }
        });
    },
    pageChange(page) {
      this.getItems(page);
    },
    search(searchValue) {
        this.titleSort = "";
        this.directionSort = null;
        this.searchValue = searchValue;
        this.getItems();
    },
    sort(code) {
      this.titleSort = code;
      this.directionSort === 1
        ? (this.directionSort = 0)
        : (this.directionSort = 1);
      this.getItems();
    },
    openModalCreate() {
      this.isCreateOpen = true;
      this.newItem.title = null;
      this.newItem.code = null;
    },
    createItem() {
      this.checkedValidatedForm();
      if (this.isValidateForm) {
        axios
          .post("nsi/" + this.dictionaryApiName, this.newItem)
          .then(() => {
            this.getItems();
          })
          .catch((err) => {
            console.log(err);
          });
        this.newItem.title = null;
        //this.newItem.code = null;

        this.isCreateOpen = false;
      }
    },
    checkedValidatedForm() {
      const currForm = this.isCreateOpen ? this.newItem : this.editItem;
      const vc = this.validatedForm;
      //vc.code = checkFieldEmpty(currForm?.code);
      vc.title = checkFieldEmpty(currForm?.title);
    },
    openModalEdit(id) {
      this.isEditOpen = true;
      axios.get("nsi/" + this.dictionaryApiName + "/" + id).then((res) => {
        const response = res.data;
        this.editItem.id = response.id;
        this.editItem.code = response.code;
        this.editItem.title = response.title;
      });
    },
    updateItem() {
      this.checkedValidatedForm();
      if (this.isValidateForm) {
        axios
          .put("nsi/" + this.dictionaryApiName + "/" + this.editItem.id, this.editItem)
          .then(() => {
            this.getItems();
          });
        this.isEditOpen = false;
      }
    },
    getTemplate() {
      let currentURL = "/nsi/" + this.dictionaryApiName + "/getImportTemplate";
      utils.downloadFileExport(currentURL, null);
    },
    fileChanged(e) {
      if (e.target.files[0]) {
        this.file = e.target.files[0];
        this.fileName = e.target.files[0].name;
      }
    },
    uploadFile() {
      if(!this.file) {
        this.fileError = "Выберите файл";
        return;
      }
      utils.uploadFile(this.file, "/nsi/" + this.dictionaryApiName + "/upload")
        .then(() => {
          this.isUploadOpen = false;
          this.getItems();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  mounted() {
    this.getItems();
  },
  computed: {
    isValidateForm() {
      return checkAllFieldsEmpty(this.validatedForm);
    },
    requestParams() {
      return {
        page: this.page,
        count: 10,
        search: {
          text: this.searchValue || "",
          fieldNames: [],
          allTextFields: true,
          nonStrictMatch: true,
          lessSymbols: true
        },
        sortItems: [
          {
            fieldName: this.titleSort || "id",
            direction: this.directionSort || 0,
          },
        ],
        //filterItems: [],
      };
    },
  }
};
</script>
<style scoped>
.modal-register {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.input__label_error {
    color: red;
    top: -17px;
    /*left: 17px;*/
    background: none;
    box-shadow: none;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    position: absolute;
}
</style>