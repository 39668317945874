<template>
  <div >
    <auth-layout>
      <template v-slot:title>
        <span class="title-yellow">Восстановить</span> <span>пароль</span>
      </template>
      <template v-slot:inputs>
        <takeout-layout v-if="loginWithPhone" @click="disableUserNotExistsMessage">
          <input-field class="input-component" @keydown.space="(event) => event.preventDefault()" @keydown="disableRecoveryErrorMessage" v-model:value="this.login"  label="Номер телефона" :label-error="validResetPass || recoveryErrorMessage"
                       type="tel"/>
        </takeout-layout>
        <takeout-layout v-else @click="disableUserNotExistsMessage">
          <input-field class="input-component" @keydown.space="(event) => event.preventDefault()" :maxLength="25" v-model:value="this.login" @keydown="disableRecoveryErrorMessage"   label="Адрес электронной почты" :label-error="validationMessage || recoveryErrorMessage"
                       type="email" @input="validateEmail" :errors="this.errors"/>
        </takeout-layout>
      </template>
      <template v-slot:other>
        <div class="additional-link" @click="selectWithLogin">
          <p v-if="loginWithPhone">
            Ввести адрес электронной почты
          </p>
          <p v-else>
            Ввести номер телефона
          </p>
        </div>
      </template>
      <template v-slot:submit>

        <div @click.self="disableRecoveryInstructionsModal" v-if="isInstructionsModal" class="h-[100vh] w-[100vw] fixed
                                               backdrop-blur
                                               flex items-center justify-content-center"
        style="background-color: rgba(0,0,0, 0.7)">
          <div @click.stop class="h-[250px] w-[320px] md:h-[250px] md:w-[450px] bg-white
                      border-amber-300 border-2 flex flex-col justify-content-center
                      gap-20 items-center text-center " >
            <p class="">На указанный при регистрации email были высланы инструкции по восстановлению пароля.</p>
            <button class="bg-amber-400 border-1 w-25 h-15 hover:cursor-pointer z-50"
                    @click.self="disableRecoveryInstructionsModal">OK
            </button>
          </div>

        </div>

        <div class="login-button-wrapper">
          <takeout-layout takeout="left">
            <button-component class="login-button" @click="onSubmit()">
              Восстановить пароль
              <template #icon-right>
                <img src="@/assets/images/svg/arrow-next.svg" alt="">
              </template>
            </button-component>
          </takeout-layout>
        </div>
      </template>
    </auth-layout>
  </div>
</template>

<script>
import InputField from "@/components/ui/InputField.vue";
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import buttonComponent from "@/components/ui/ButtonComponent.vue";
import AuthLayout from "@/modules/auth/AuthLayout.vue";

export default {
  name: "resetPass",
  components: {
    AuthLayout,
    buttonComponent,
    TakeoutLayout,
    InputField
  },
  data() {
    return {
      login: null,
      loginWithPhone: false,
      validationMessage: ''
    }
  },
  computed: {
    errors() {
      return this.$store.state.auth.errorsLogin
    },
    isUserExists() {
      return !this.$store.state.auth.isUserExists
    },
    isInstructionsModal() {
      return this.$store.state.auth.isRecoveryInstructionsModal
    },
    recoveryErrorMessage() {
      return this.$store.state.auth.recoveryErrorMessage
    }
  },
  methods: {
    validateFields() {
      this.validationMessage = ''
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      if (this.loginWithPhone) {
        if (this.login.length < 16) {
          this.validationMessage = 'Номер телефона должен состоять минимум из 11 цифр'
          return false
        }
      }
      else if(!emailRegex.test(this.login)) {
        this.validationMessage = 'Введите корректный адрес электронной почты'
        return false
      }
      return true
    },
    async onSubmit() {
      if (this.validateFields()) {
         await this.$store.dispatch('auth/resetPass', this.login)
      }
    },
    selectWithLogin() {
      this.validationMessage = ''
      this.disableUserNotExistsMessage()
      this.loginWithPhone = !this.loginWithPhone
      this.login = ''
      this.$store.dispatch('auth/disableRecoveryErrorMessage')
    },
    disableRecoveryErrorMessage() {
      if (this.recoveryErrorMessage) {
        this.$store.dispatch('auth/disableRecoveryErrorMessage')
      }
    },
    disableRecoveryInstructionsModal() {
      this.$store.dispatch('auth/disableRecoveryInstructionsModal')
    },
    disableUserNotExistsMessage() {
      this.$store.dispatch('auth/disableUserNotExistMessage')
    },
    validateEmail() {
      if (!this.loginWithPhone) {
        this.login = this.login.replace(/[^a-zA-Z0-9\s.,\-=/&?:%;№#$@()^*|!_]/g, "")
      }
    }
  }
}

</script>

<style scoped>

</style>