import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import ErrorPage from '../views/ErrorPage.vue'
import store from '@/store/index';
import ResetPass from '../views/ResetPass.vue'
import ChangePass from '../views/ChangePass.vue'
import CreatePass from "@/modules/auth/views/CreatePass.vue";
export const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { isPublic: true }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: { isPublic: true }
  },
  {
    path: '/errorPage',
    name: 'ErrorPage',
    component: ErrorPage,
    meta: { isPublic: true }
  },
  {
    path: '/resetPass',
    name: 'ResetPass',
    component: ResetPass,
    meta: { isPublic: true }
  },
  {
    path: '/changePass',
    name: 'ChangePass',
    component: ChangePass,
    meta: { isPublic: true }
  },
  {
    path: '/create-pass',
    name: 'CreatePass',
    component: CreatePass,
    meta: { isPublic: true }
  }
];

export const registerGuards = (router) => {
  router.beforeEach(async (to/*, from*/) => {
    if (to.meta.isPublic !== true && !store.getters['auth/isAuthenticated']) {
      return { path: '/login', query: { returnUrl: to.fullPath } }
    }
  })
};