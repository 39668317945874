<template>
  <div>
    <nsi-base dictionary-name="Классы" dictionary-api-name="grade" />
  </div>
</template>
<script>
import NsiBase from '../helpers/NsiBase.vue';
export default {
  name: "grades",
  components: {
    NsiBase
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
<style scoped>
</style>