<template>
   <div class="lk container-page" v-if="kitInfo">
     <title-page-component>
       <template v-slot:title>
        Карточка набора <span class="title-span">{{ kitInfo.code }}</span>
       </template>
     </title-page-component>
     <div class="flex justify-between text-3xl mb-12 items-center">
      <p>Атрибуты набора</p>
      <takeout-layout takeout="right">
             <button-component width="540" max-width="540" @click="switchHistoryModal">
               История
      </button-component>

      <modal-component @close-modal="switchHistoryModal" v-if="isHistoryOpen">
       <template v-slot:title>
         <span class="text-4xl">История выдачи набора {{ kitInfo.kitTypeTitle }}</span>
         </template>
        <template v-slot:content>
        <div class="overflow-auto flex flex-col w-[712px]" @click.stop>
        <table>
          <thead class="text-lg font-bold whitespace-nowrap">
            <tr>
            <th>Дата и время выдачи</th>
            <th>ФИО кому выдан</th>
            <th>Срок возврата</th>
            <th>Дата возврата</th>
            <th>ФИО выдавшего</th>
          </tr>
          </thead>
          <tbody class="text-sm whitespace-nowrap">
            <tr v-for="item in kitGrantHistoryData">
            <td>{{ getProcessedDate(item.grantedDt) }}</td>
            <td>{{ item.grantedTo }}</td>
            <td>{{ getDayLeftCount(item.grantedDt, item.deadlineDt) || '-' }}</td>
            <td>{{ getProcessedDate(item.deadlineDt) }}</td>
            <td>{{ item.grantedBy }}</td>
          </tr>
          </tbody>
        </table>
          </div>
        </template>
      </modal-component>
        </takeout-layout>
     </div>
     <div class="flex gap-10 items-center justify-between mb-16">
     <div class="flex flex-col gap-[0.5rem]">
      <ul class="flex flex-col gap-[0.5rem] !mb-2 text-lg">
        <li>{{kitInfo?.kitTypeTitle || '-' }}</li>
        <li>номер набора – <span class="text-main font-bold">{{ kitInfo?.kitTypeId || '-' }}</span></li>
        <li>срок до <span class="text-main font-bold">{{ dateProcessor(kitInfo?.grant?.deadlineDt) || '-' }}</span></li>
      </ul>

       <takeout-layout takeout="left">
        <button-component @click="onClickPrintQR">
           <template v-slot:icon-left>
             <img src="@/assets/images/svg/printQR.svg" class="w-full h-full" alt="">
           </template>
           Распечатать QR
         </button-component>
       </takeout-layout>

       <div class="lk__qr">
        <vue-qrcode class="!w-[242px] !h-[242px] mt-2"
          :value=kitInfo.qr
        />
       </div>
     </div>
     <kit-card-component :grantedDate="dateProcessor(kitInfo?.grant?.grantedDt)" image="kit-robot.png" :kitInfo="kitInfo" v-if="kitInfo" />
    </div>

    <div class="flex justify-content-end mb-16">
     <takeout-layout v-show="kitInfo.stateId !== 2 && kitInfo.stateId !== 4" takeout="right">
       <button-component  width="540" max-width="540" @click="switchGiveModal">
         {{ `${kitInfo?.grant?.isCompleted === false ? 'Сдать' : 'Выдать'} набор` }} {{kitInfo.code}}
       </button-component>

       <modal-component @close-modal="switchGiveModal"
                        v-if="(isGiveOpen && !isSuccessModal) && kitInfo.stateId === 1"
       >
         <template v-slot:title></template>
         <template v-slot:content>

           <div class="flex flex-col gap-12" @click.stop>
             <takeout-layout takeout="left">
               <select-component :options="users" class="w-full" label="Выбрать пользователя"
                                 v-model:selected-option="grantedSelectUser"
                                 v-model:selected-option-id="grantedSelectId"
               />
             </takeout-layout>
             <div class="flex justify-between gap-16">
               <takeout-layout takeout="left">
                 <button-component @click="switchPrintModal">
                   <template v-slot:icon-left>
                     <img :src="require('@/assets/images/svg/crop-free.svg')" />
                   </template>
                   Сканировать
                 </button-component>
               </takeout-layout>
               <takeout-layout>
                 <button-component @click="grantedKit" :is-disabled="!grantedSelectId">
                   <template v-slot:icon-left>
                     <img :src="require('@/assets/images/svg/check-circle-outline.svg')" />
                   </template>
                   Подтвердить
                 </button-component>

               </takeout-layout>
             </div>
           </div>

           <modal-component @close-modal="switchPrintModal"
                            v-if="(isGiveOpen && !isSuccessModal && isPrintModal) && kitInfo.stateId === 1"
           >
             <template v-slot:title></template>
             <template v-slot:content>
           <div class="flex flex-col justify-content-center align-content-center gap-12" @click.stop>
             <p class="text-xl mt-2">Сканер не обнаружен!</p>
             <div class="flex justify-between gap-16">
                 <button-component @click="switchPrintModal">
                   Назад
                 </button-component>
             </div>
           </div>
             </template>
           </modal-component>

         </template>
       </modal-component>

       <modal-component :side-padding="15"
                        :min-width="450"
                        @close-modal="switchGiveModal"
                        v-if="(isGiveOpen && !isSuccessModal) && kitInfo.stateId === 3"
       >
              <template v-slot:title>
                <p class="give-in-modal-header max-w-[400px] ml-3">Сдача набора {{ kitInfo.code }}</p>
              </template>
      <template v-slot:content>

        <div class="ml-3 my-4 flex flex-col gap-x-5 gap-y-4" @click.stop>
          <div class="flex gap-5">
              <p class="font-weight-bold">№</p>
              <p class="font-weight-bold">Деталь</p>
          </div>

          <div v-if="kitInfo.items.length" class="overflow-y-auto max-h-[200px]">
          <div v-for="(item, index) of kitInfo.items" class="flex gap-[13px] w-[300px] my-3">
            <p>{{index + 1}}</p>
            <checkbox @change="selectedKit(item)"
                      :is-checked="!!selectKit?.includes(item.id)"
            />
            <p class="name-items flex-1">{{ item.code }}</p>
            <img
                title="Распечатать QR-код"
                @click="printQr(item.id)"
                class="icon-registers__item"
                src="@/assets/images/qr.png"
                alt=""
            />
          </div>
          </div>
          <div v-else>
              <p class="font-weight-bold font-italic text-md">Комплектация отсутсвует!</p>
          </div>

          <takeout-layout :width="90" class="w-[400px]" takeout="right">
            <button-component  :max-width="400" :width="400" @click="onClickGiveInKit(kitInfo.id)"
            >
              Сдать набор <span class="name-kit"> {{ kitInfo.code }}</span>
            </button-component>
          </takeout-layout>
        </div>

      </template>
     </modal-component>

       <modal-component :side-padding="15"
                        :min-width="450"
                        @close-modal="switchGiveModal"
                        v-if="isGiveOpen && isSuccessModal"
                        no-close-cross
                        click-outside-disabled
                        content-centered
       >
         <template v-slot:title>
           <div class="flex flex-col gap-5">
             <p class="give-in-modal-header">Набор {{ kitInfo.code }} успешно {{ kitInfo.stateId === 3 ? 'сдан' : 'выдан' }}</p>
               <button-component is-centered
                                 @click="onClickCloseModalAndRefresh"
                                 :max-width="461"
                                 :width="461"
               >
                 <template v-slot:icon-left>
                   <img :src="require('@/assets/images/svg/check-circle-outline.svg')" />
                 </template>
                 Назад
               </button-component>
           </div>
         </template>
       </modal-component>

       <modal-component @close-modal="this.errorModal = null" v-if="this.errorModal">
         <template #title>
           Ошибка!
         </template>
         <template #content>
           {{ this.errorModal }}
         </template>
       </modal-component>


     </takeout-layout>
    </div>

    <div class="flex justify-between text-3xl mb-12 items-center">
      Информация о комплектности
     <div class="flex gap-1 items-center">
      <div class="rounded-full border-[10px] border-main">
        <img class="w-full"
             :src="require(`@/assets/images/svg/${
          kitInfo.stateId === 1
          ? 'partyboto.svg'
          : 'robot239_error_logo.svg'}`)"
             alt="">

      </div>

      <div class="flex flex-col text-2xl">
        <p>Статус:</p>
        <p :class="[kitInfo.stateId === 1 ? 'text-green-500' : 'text-red-500']">
          {{ kitInfo.stateTitle }}
        </p>
      </div>
     </div>
     </div>
    <div class="flex gap-5 mb-16" v-if="kitInfo.items.length">
     <div class="flex gap-3" v-for="item of kitInfo.items">
      <detail-card-component :detail-card-info="item">
        {{ item.itemTypeTitle }}
        <template v-slot:image>
          <img src="@/assets/images/detail_0.png" class="w-40 absolute h-auto" alt="">
       </template>
      </detail-card-component>
     </div>
    </div>
    <div class="flex justify-center items-center mb-16" v-else>
      Комплектация отсутствует
    </div>

     <div class="flex justify-end mb-16">
     <takeout-layout takeout="right">
             <button-component width="540" max-width="540" @click="toPartsPage">
              Добавить комплектующие
             </button-component>
     </takeout-layout>

          </div>
   </div>
 </template>
 <script>

 import TitlePageComponent from "@/components/ui/TitlePageComponent.vue";
 import KitCardComponent from "@/modules/kit/KitCardComponent.vue";
 import DetailCardComponent from "@/modules/kit/DetailCardComponent.vue";
 import ButtonComponent from "@/components/ui/ButtonComponent.vue";
 import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
 import ModalComponent from "@/components/ui/ModalComponent.vue";
 import api from "@/common/api/api";
 import utils from "@/common/utils";
 import VueQrcode from 'vue-qrcode';
 import Checkbox from "@/components/ui/Checkbox.vue";
 import SelectComponent from "@/components/ui/SelectComponent.vue";

 export default {
   name: "Kit",

   components: {
     TakeoutLayout,
     DetailCardComponent,
     KitCardComponent,
     ButtonComponent,
     TitlePageComponent,
     ModalComponent,
     VueQrcode,
     Checkbox,
     SelectComponent
   },
   data() {
     return {
       grantedSelectUser: null,
       grantedSelectId: null,
       isGrantedDeadlineDt: null,
       selectKit: [],
       isHistoryOpen: false,
       isGiveOpen: false,
       kitInfo: null,
       grantHistory: null,
       kitGrantHistoryData: [],
       isSuccessModal: false,
       addPartsData: {},
       isPrintModal: false,

       errorModal: null,
     }
   },
   methods: {
    getDeadline() {
    const currentDate = new Date();
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);

    const day = String(nextDate.getDate()).padStart(2, '0');
    const month = String(nextDate.getMonth() + 1).padStart(2, '0');
    const year = nextDate.getFullYear();

    this.isGrantedDeadlineDt = `${year}-${month}-${day}`;
    },
    toPartsPage() {
      const data = {
        mode: 'partsSelect',
        kit: this.kitInfo.id,
        currentItems: this.kitInfo.items.length ? this.kitInfo.items.map(el => el.id) : []
      }
      localStorage.setItem('selectKit', JSON.stringify(data))
      this.$router.push( { name: 'register-parts'})
    },
    switchPrintModal() {
       this.isPrintModal = !this.isPrintModal
    },
    grantedKit() {
      api.grantKit({
            kitId: this.kitInfo.id,
            grantedToId: this.grantedSelectId,
            deadlineDt: new Date(this.isGrantedDeadlineDt).toISOString()
          }).then(res => {
            if (res.status === 200) {
              this.isSuccessModal = true
            }
          }
      ).catch(error => {
        this.errorModal = error.response.data.errors ? error.response.data?.errors[0] : error.response.data.title
      }
      )
    },
    onClickGiveInKit(id) {
      const params = {
        kitId: id,
        missingItems: []
      }
      params.missingItems = this.kitInfo.items.filter(item =>
          !this.selectKit.some(chosenItem => chosenItem === item.id))
          .map(filteredItem => filteredItem.id
      );
      api.giveInKit(params)
          .then(res => {
                if (res.status === 204) {
                   this.isSuccessModal = true
                }
              }
          ).catch(error => {
        this.errorModal = error.response.data?.errors[0] || error.response.data.title
      })
    },
     getProcessedDate(date) {
       const dateObject = new Date(date);

       let day = dateObject.getDate();
       let month = dateObject.getMonth() + 1;
       const year = dateObject.getFullYear();

       day = day < 10 ? '0' + day : day;
       month = month < 10 ? '0' + month : month;

       return day + '.' + month + '.' + year;
     },
     onClickCloseModalAndRefresh() {
       this.isGiveOpen = false
       this.loadKitInfo()
     },
     getDayLeftCount(startDate, deadlineDate) {
       let date1 = new Date(startDate);
       let date2 = new Date(deadlineDate);
       let timeDifference = date2 - date1;
       let daysDifference = Math.abs(Math.floor(timeDifference / (1000 * 60 * 60 * 24)));
       if (daysDifference === 0) {
         daysDifference = 1
       }
       function daysCountWord(daysDifference) {
         if (daysDifference === 1) {
           return ' день'
         }
         if (daysDifference === 2 || daysDifference === 3 || daysDifference === 4) {
           return ' дня'
         }
         else return ' дней'
       }

       return daysDifference + daysCountWord(daysDifference);
     },
      async getKitGrantHistory() {
      let params = {
        page: 0,
        count: 0,
        search: {
          text: '',
          fieldNames: [
          ],
          allTextFields: true,
          nonStrictMatch: true
        },
        sortItems: [],
        filterItems: [
          {
            fieldName: 'kitId',
            condition: 0,
            value: `${this.kitInfo.id}`,
            valueTo: ''
          }
        ]
      }
     await api.getKitGrantHistory(params)
          .then(res =>  this.kitGrantHistoryData = res.data.items)
          .catch(error => {
            this.errorModal = error.response.data.title || error.response.data?.errors[0]
          })
    },

    async loadKitInfo() {
     await api.getKitInfo(this.$route.params.id).then((res) => {
     this.kitInfo =  res.data;
    });
    },

    switchHistoryModal() {
      this.isSuccessModal = false
      this.getKitGrantHistory()
      this.isHistoryOpen = !this.isHistoryOpen
    },

    switchGiveModal() {
      this.isSuccessModal = false
      this.isGiveOpen = !this.isGiveOpen
    },

    onClickPrintQR() {
       let url = api.getQRPrintOne(this.$route.params.id, 'kit')
       utils.downloadAndPrintJson(url, null)
    },
    dateProcessor(isoDate) {
      if (isoDate) {
        let dateObj = new Date(isoDate);

        let day = dateObj.getDate();
        let month = dateObj.getMonth() + 1;
        let year = dateObj.getFullYear();
        return day + '.' + month + '.' +  year
      }
      else return '-'
    },
     printQr(id) {
       let url = api.getQRPrintOne(id, 'parts');
       if (url) utils.downloadAndPrintJson(url, null);
     },
     selectedKit(item) {
       const index = this.selectKit?.indexOf(item.id);
       if (index !== -1) {
         this.selectKit?.splice(index, 1);
       } else {
         this.selectKit.push(item.id);
       }
     },

   },

   created() {
    this.loadKitInfo();
    },
  async mounted() {
      this.$store.dispatch('users/getUsers')
      this.getDeadline()
       if (localStorage.getItem('selectKit')) {
         this.addPartsData = [...JSON.parse(localStorage.getItem('selectKit')).items, ...JSON.parse(localStorage.getItem('selectKit')).currentItems]
         let kitId = JSON.parse(localStorage.getItem('selectKit')).kit
         localStorage.removeItem('selectKit')
         api.addItemsToKit(kitId, this.addPartsData)
         this.loadKitInfo();
       }
    },

   computed: {
    users() {
      return this.$store.getters['users/usersForSelect']
    },
   }
 };
 </script>

 <style lang="scss" scoped>
 th, td {
  padding: 12px;
 }
 .title-span {
   font-weight: 700;
   color: #FFCE38;
 }

 .lk {
   &__user-info {
     justify-content: space-between;
     margin: 50px 0;
     display: flex;
     flex-direction: row;
     gap: 20px;
     @media (max-width: 768px) {
       flex-direction: column-reverse;
       align-items: center;
       gap: 60px;
     }
   }

   &__other-info {
     display: flex;
     flex-direction: column;
     gap: 10px;
     width: 260px;
     @media(max-width: 768px) {
       width: auto;
     }
   }

   &__qr {
     width: 260px;
     height: 260px;
     display: flex;
     align-items: center;
     justify-content: center;
     @media(max-width: 768px) {
       width: auto;
       height: auto;
     }

     img {
       width: 260px;
       height: 260px;
       @media (max-width: 768px) {
         width: 100%;
         height: auto;
       }
     }
   }

   &__other-info-list {
     display: flex;
     flex-direction: column;
     align-items: flex-end;

     li {
       font-size: 18px;
       font-weight: 800;
       line-height: 25px;
       text-align: right;

       span {
         color: #FFCE38;
         margin: 0 10px;
       }
     }
   }
 }

 .table-staff {
   margin: 50px 0;

   table {
     margin: 0 auto;
   }

   td {
     font-size: 18px;
     font-weight: 400;
     line-height: 25px;

     color: #343C44;
   }

   th {
     font-size: 18px;
     font-weight: 700;
     line-height: 25px;

     color: #6C757D;
   }

   td, th {
     border: 1px solid #CED4DA;
     padding: 10px;
   }

   @media(max-width: 1024px) {
     display: none;
   }

   &_mobile {
     display: none;
     @media(max-width: 1024px) {
       display: block;
     }
   }
 }
 .icon-registers__item {
   margin: 0 auto;
   height: 20px;
   cursor: pointer;
 }
 .give-in-modal-header {
   font-size: 40px;
   font-weight: 600;
 }
 .name-items {
   max-width: 300px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
 }
 .name-kit {
   max-width: 150px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
 }
 </style>