import axios from "axios";
import runtimeConfig from '@/common/runtime-config';

export default {
  getGroupTitle(groupName)
  {
    switch (groupName) {
        case 'Teacher': return 'Преподаватель';
        case 'Superuser': return 'Суперпользователь';
        case 'Administrator': return 'Адиминистратор';
        case 'Student': return 'Ученик';
        default: return '-';
    }
  },
  downloadFileJson(url, params) {
    return axios.get(url, {params: params}).then((response) => {
      let bytes = Uint8Array.from(atob(response.data.content), c => c.charCodeAt(0))          
      const blob = new Blob([bytes], { type: response.data.contentType });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = url;
      a.download = `${response.data.fileName}`;

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);   
    }).catch((err) => console.log(err));
  },  
  downloadAndPrintJson(url, params)
  {
    axios.get(url, {params: params}).then((response) => {
      let bytes = Uint8Array.from(atob(response.data.content), c => c.charCodeAt(0))     
      const blob = new Blob([bytes], { type: response.data.contentType });
      const url = window.URL.createObjectURL(blob);
  
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = url
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    }).catch((err) => console.log(err));
  },

  downloadFileExport(url, params) {
    return axios.post(url, params).then((response) => {
      let bytes = Uint8Array.from(atob(response.data.content), c => c.charCodeAt(0))          
      const blob = new Blob([bytes], { type: response.data.contentType });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = url;
      a.download = `${response.data.fileName}`;

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);   
    }).catch((err) => console.log(err));
  },   
  downloadFile(url, filename, params, nameFromParams) {
    var options = { responseType: "blob" };
    if (params) {
      options.params = params;
    }
    return axios.get(url, options).then((response) => {
      const blob = new Blob([response.data], { type: response.data.type });
      const link = document.createElement("a");
      const contentDisposition = response.headers["content-disposition"];
      filename = filename || "download";
      if (contentDisposition && !nameFromParams) {
        const matches = contentDisposition.match(/filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?/);
        if (matches?.length === 2) {
          filename = matches[1];
        }
      }
      link.href = URL.createObjectURL(blob);  
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    });
  },
  getPublicBaseUrl() {
    return runtimeConfig.publicBaseUrl;
  },
  uploadFile: function (file, url) {
    let formData = new FormData();
    formData.append('file', file);
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};
