import Swal from 'sweetalert2'

const langRu = {
  errorLoading:function(){return"Невозможно загрузить результаты"},
  loadingMore:function(){return"Загрузка данных…"},
  noResults:function(){return"Совпадений не найдено"},
  searching:function(){return"Поиск…"},
  removeAllItems:function(){return"Удалить все элементы"},
  inputTooShort: function() {return "Введите больше символов для начала поиска"}
}

var select2SettingsMixin = {
  language: langRu,
  allowClear: true,
  matcher: function (params, data) {
    if (!params.term)
      return data;
    if (data.text.toLowerCase().startsWith(params.term.toLowerCase()))
      return data;
    else
      return null;
  }
};

export default {
  langRu,
  emptySelectOptionText: '-- не выбрано --',
  validatorMessageEmpty: 'Введите данные',
  pageSize: 10,
  messengerFileLimit: 5,
  messengerFileMaxSize: 10485760,
  confirmOptions: {
    delete: {
      title: 'Удаление',
      text: 'Вы действительно хотите удалить эту запись?',
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Отмена',
      showCancelButton: true,
      icon: 'warning'
    },
    changeStatus: {
      title: 'Изменение статуса',
      text: 'Изменить статус этой записи?',
      confirmButtonText: 'Изменить',
      cancelButtonText: 'Отмена',
      showCancelButton: true,
      icon: 'warning'
    },
  },

  errorTitle: 'Ошибка',
  validationError: 'Проверьте правильность заполнения полей.',
  commonError: 'Произошла ошибка. Обратитесь в техническую поддержку.',
  commonSuccess: 'Данные успешно сохранены.',
  select2Settings: select2SettingsMixin,
  select2SettingsSearchAll: {
    language: langRu,
    allowClear: true
  },
  select2SettingsMultiple: Object.assign({ multiple: true }, select2SettingsMixin),
  select2SettingsNoClear: {
    language: langRu,
    allowClear: false
  },
  yesNoList: [{
    id: null, text: 'Не выбрано'
  }, {
    id: true, text: 'Да'
  }, {
    id: false, text: 'Нет'
  }],

  userType: {
    internal: 1,
    external: 2
  },



  alert: Swal.mixin({
    //customClass: {
    //  confirmButton: 'btn btn-success',
    //  cancelButton: 'btn btn-danger'
    //},
    //buttonsStyling: false
  }),
  removeFromArr(arr) {
    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
      what = a[--L];
      while ((ax = arr.indexOf(what)) !== -1) {
        arr.splice(ax, 1);
      }
    }
    return arr;
  },

  Permissions: {

  },

  monthList: [
    { id: 1, text: "Январь"},
    { id: 2, text: "Февраль"},
    { id: 3, text: "Март"},
    { id: 4, text: "Апрель"},
    { id: 5, text: "Май"},
    { id: 6, text: "Июнь"},
    { id: 7, text: "Июль"},
    { id: 8, text: "Август"},
    { id: 9, text: "Сентябрь"},
    { id: 10, text: "Октябрь"},
    { id: 11, text: "Ноябрь"},
    { id: 12, text: "Декабрь"},
  ],
  monthListSimple: [
    'Январь','Февраль','Март','Апрель',
    'Май','Июнь','Июль','Август',
    'Сентябрь','Октябрь','Ноябрь','Декабрь'
  ],

};