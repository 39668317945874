import { createStore } from "vuex";
import auth from "@/modules/auth/store/index";
import handbooks from "@/modules/registers/store/handbooks";
import users from "@/modules/registers/store/users.js";
import kits from "@/modules/registers/store/kits"
import item from "@/modules/item/store/index"


export default createStore({
  mutations: {
  },
  actions: {
  },
  getters: {
  },
  modules: {
    auth,
    handbooks,
    users,
    kits,
    item,
  },
});
