<template>
  <li v-if="menuItem" class="menu__item">
    <div>
      <router-link
        @click="menuItem.url.length >= 1 ? this.$emit('selectItem') : ''"
        :to="menuItem.url"
      >
        <div
          class="menu__root-item"
          @click="menuItem.expanded = !menuItem.expanded"
        >
          <img
            v-if="menuItem.icon === 'chat'"
            class="collapse-icon"
            src="@/assets/images/svg/menu/chat.svg"
            alt=""
          />
          <img
            v-else-if="menuItem.icon === 'about'"
            class="collapse-icon"
            src="@/assets/images/svg/menu/about.svg"
            alt=""
          />
          <img
            v-else-if="menuItem.icon === 'qr'"
            class="collapse-icon"
            src="@/assets/images/svg/menu/qr.svg"
            alt=""
          />
          <img
            v-else-if="menuItem.icon === 'notices'"
            class="collapse-icon"
            src="@/assets/images/svg/menu/notices.svg"
            alt=""
          />
          <img
            v-else-if="menuItem.icon === 'reports'"
            class="collapse-icon"
            src="@/assets/images/svg/menu/reports.svg"
            alt=""
          />
          <img
            v-else-if="menuItem.icon === 'registers'"
            class="collapse-icon"
            src="@/assets/images/svg/menu/registers.svg"
            alt=""
          />
          <img
            v-else-if="menuItem.icon === 'administration'"
            class="collapse-icon"
            src="@/assets/images/svg/menu/administration.svg"
            alt=""
          />
          <img
            v-else-if="menuItem.icon === 'users'"
            class="collapse-icon"
            src="@/assets/images/svg/menu/users.svg"
            alt=""
          />
          <img
            v-else-if="menuItem.icon === 'handbooks'"
            class="collapse-icon"
            src="@/assets/images/svg/menu/handbooks.svg"
            alt=""
          />
          <img
            v-else-if="menuItem.icon === 'home'"
            class="collapse-icon"
            src="@/assets/images/svg/menu/home.svg"
            alt=""
          />
          <img
            v-else-if="menuItem.icon === 'settings'"
            class="collapse-icon"
            src="@/assets/images/svg/menu/settings.svg"
            alt=""
          />
          <img
            v-else-if="menuItem.icon === 'exit'"
            class="collapse-icon"
            src="@/assets/images/svg/menu/exit.svg"
            alt=""
          />
          <img
            v-else
            class="collapse-icon"
            src="@/assets/images/svg/menu/chat.svg"
            alt=""
          />
          <span class="menu__root-item-name">{{ menuItem.name }}</span>
        </div>
      </router-link>
      <div class="menu__sub-items" v-show="menuItem.expanded">
        <ul>
          <menu-item-component
            @select-item="this.$emit('selectItem')"
            v-for="subItem in menuItem.items"
            :key="subItem.id"
            :item="subItem"
          />
        </ul>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: "menuItemComponent",
  components: {},
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      menuItem: this.item,
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/theme/default/colors.scss";

.switch {
  margin-top: 32px;
}

.menu__btns {
  position: absolute;
  margin-top: 45px;
  margin-left: 25px;
}

.menu_list {
  p,
  a,
  span {
    margin: 0;
    text-decoration: none;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 150%;
    align-items: center;
    letter-spacing: 0.15px;
    text-transform: uppercase;
    color: $whiteMist;
  }

  p {
    display: block;
    margin-bottom: 16px;
  }

  a {
    display: block;
  }

  span {
    display: inline-block;
  }
}

.menu__item {
  cursor: pointer;

  .menu__sub-items {
    padding-top: 5px;
    padding-left: 16px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  .collapse-icon {
    position: relative;
    min-width: 18px;
    width: 18px;
    height: 18px;

    &::after {
      position: absolute;
      content: "";
      right: calc(50% - 4px);
      bottom: calc(50% - 4px);
      width: 8px;
      height: 8px;
      border-right: 4px solid $whiteMist;
      border-bottom: 4px solid $whiteMist;
      border-left: 4px solid transparent;
      border-top: 4px solid transparent;
      transform-origin: center;
      transform: rotate(-45deg);
      transition-property: transform;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
    }

    &_expanded::after {
      transform: rotate(45deg) translate(-1px, -1px);
    }
  }

  .menu__root-item {
    display: flex;
    flex-direction: row;
    gap: 7px;
    padding: 8px;

    &:hover {
      border-radius: 20px;
      background-color: white;
      color: #ffce38;
    }
    &-name {
      color: black;
    }
    &-link {
      margin-left: 18px;
    }
  }

  .active-link {
    color: #acdae8;
  }
}

.menu {
  padding: 60px 22px 17px 40px;
  min-width: 230px;
}

@media (max-width: 320px) {
  .btn {
    left: 5px;
    width: 60px;
  }
}
</style>