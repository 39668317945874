<template>
  <div class="input-wrapper">
    <div class="input">
      <label
        v-if="this.inputType !== 'file' && !value && !focusInputDate && label"
        class="input__label"
        :class="{ 'date-label': this.inputType === 'date' }"
        >{{ label }}</label
      >
      <label
        v-if="labelError && this.inputType !== 'file' && !focusInputDate"
        class="input__label input__label_error"
        >{{ labelError }}</label
      >

      <div class="input__container takeout-right">
        <input
          v-if="
            inputType === 'text' ||
            inputType === 'password' ||
            inputType === 'email' ||
            inputType === 'search'
          "
          :placeholder="placeholder"
          :title="label"
          class="input__item"
          :name="name"
          :type="inputType"
          :maxlength="maxLength"
          :value="this.value"
          @input="validateInput($event.target.value)"
          @click="this.$emit('resetError')"
          @focus="focusInputDate = true"
          @blur="focusInputDate = false"
          @keydown.enter.prevent="this.$emit('search')"
        />
        <input
          v-if="inputType === 'tel'"
          v-mask="'+7(###)###-##-##'"
          class="input__item"
          :name="name"
          type="text"
          :value="this.value"
          :title="label"
          @input="validateInput($event.target.value)"
          @click="this.$emit('resetError')"
          @focus="focusInputDate = true"
          @blur="focusInputDate = false"
        />
        <textarea
          :maxlength="this.maxLength"
          v-if="inputType === 'textarea'"
          class="input__item"
          rows="5"
          :name="name"
          :value="this.value"
          :title="label"
          @input="validateInput($event.target.value)"
          @focus="focusInputDate = true"
          @blur="focusInputDate = false"
        >
        </textarea>
        <div class="file" v-if="inputType === 'file'">
          <button-component @click="openFilePicker">
            <slot></slot>
          </button-component>
          <input
            type="file"
            ref="fileInput"
            style="display: none"
            @change="handleFileChange"
          />
        </div>
        <input
          v-if="inputType === 'date'"
          class="input__item"
          :max="maxDate"
          :name="name"
          :type="this.inputType"
          :value="this.value"
          :title="label"
          @input="validateInput($event.target.value)"
          @focus="focusInputDate = true"
          @blur="focusInputDate = false"
        />
        <img
          v-if="type === 'password' && passVision"
          @click="togglePasswordVisibility"
          class="input__icon"
          src="@/assets/images/svg/pass-vision-no.svg"
          alt=""
        />
        <img
          v-if="type === 'password' && !passVision"
          @click="togglePasswordVisibility"
          class="input__icon"
          src="@/assets/images/svg/pass-vision.svg"
          alt=""
        />
        <img
          v-if="inputType === 'search'"
          @click="this.$emit('search')"
          class="input__icon"
          src="@/assets/images/svg/input-search.svg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";

export default {
  components: { ButtonComponent },
  directives: { mask },
  name: "inputField",
  props: {
    value: String || Number,
    label: String,
    labelError: String,
    name: String,
    type: String,
    placeholder: String,
    maxDate: String,
    maxLength: {
      type: Number,
      default: 9999,
    },
  },
  data() {
    return {
      inputType: this.type,
      passVision: false,
      focusInputDate: false,
    };
  },
  methods: {
    validateInput(inputValue) {
      let trimmedValue = inputValue.trim();
      if (this.inputType === 'date') {
        if (trimmedValue > this.maxDate) {
          trimmedValue = this.maxDate
          this.$emit("update:value", trimmedValue);
        } else {
          this.$emit("update:value", trimmedValue);
        }
      } else {
        this.$emit("update:value", trimmedValue);
      }
    },
    togglePasswordVisibility() {
      this.passVision = !this.passVision;
      this.inputType === "password"
        ? (this.inputType = "text")
        : (this.inputType = "password");
    },
    openFilePicker() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$emit("photoUrl", e.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
  },
  model: {
    prop: "value",
    event: "input",
  },
};
</script>

<style scoped lang="scss">
input:focus,
textarea:focus,
select:focus,
a:focus {
  outline: none;
}
.input-wrapper {
  display: flex;
  align-items: center;
}
.input {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__label {
    position: absolute;
    color: #c6c6c6;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 0;
    z-index: 4;
    top: 33%;
    left: 30px;
    pointer-events: none;

    &_error {
      color: red;
      top: -17px;
      left: 17px;
      background: none;
      box-shadow: none;
    }
  }

  &__item {
    width: 100%;
    border: 4px solid #ffce38;
    border-radius: 30px;
    padding: 10px 20px;

    &:focus {
      box-shadow: #ffce3833 0px 0px 0px 10px;
      border-color: #ffce38;
    }
  }

  &__container {
    position: relative;
    display: flex;

    align-items: center;
  }

  &__icon {
    position: absolute;
    right: 20px;
    cursor: pointer;
    z-index: 8;
  }
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button {
  display: none;
  -webkit-appearance: none;
}
input,
textarea {
  background: white;
}
input::-webkit-inner-spin-button {
  cursor: pointer;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent !important;
}
.datepicker {
  background: white;
  --dp-button-icon-right: 20px;
}
:deep(.datepicker) .dp__input {
  border: none;
  padding: 0;
}
.input-slot-image {
  height: 20px;
  width: auto;
  margin-left: 5px;

  pointer-events: none;
}
.date-label {
  background-color: white;
  width: calc(100% - 60px);
  padding: 0 10px;
  margin-left: -15px;
}
</style>