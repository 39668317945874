import axios from "axios";

export default {
    namespaced: true,
    state: () => ({
        kits: [],
    }),
    mutations: {
        setKit(state, res) {
            state.kits = res
        }
    },
    getters: {
        kitsForSelector(state) {
            return state.kits.map(item => {
                return {
                    id: item.id,
                    title: item.code
                }
            })
        },
        kitFeaturesForSelector(state) {
            let uniqueFeatures = []
            state.kits.forEach(el => {
                if (el.feature && uniqueFeatures.indexOf(el.feature) === -1) {
                    uniqueFeatures.push(el.feature)
                }
            })
            let result = []
            uniqueFeatures.map(item => {
                result.push({
                    id: item,
                    title: item
                })
            })
            return result
        }
    },
    actions: {
        getKit(context) {
            axios.get('main/kit')
                .then(res => {
                    context.commit('setKit', res.data)
                })
        }
    }
}