const requiredField = 'Обязательное поле'

export function checkFieldEmpty(formFiled) {
    if (!(formFiled?.length || formFiled)) {
        return  requiredField
    } else {
        return  ''
    }
}
export function checkAllFieldsEmpty(obj) {
    for (let key in obj) {
        if (typeof obj[key] === 'string' && obj[key].trim() !== '') {
            return false;
        }
    }
    return true;
}
