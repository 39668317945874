<template>
  <div class="layout-start">
    <div class="logo">
      <img src="@/assets/images/svg/robot239.svg" alt="">
    </div>
    <div class="form">
      <div class="form__title">
        <slot name="title">
        </slot>
      </div>
        <div class="form__body">
          <div class="input-list">
            <slot name="inputs"></slot>
          </div>
          <div class="other-content">
            <slot name="other"></slot>
          </div>
        </div>
        <div class="form__submit">
          <slot name="submit"></slot>
        </div>
        <div class="form__download">
          <slot name="links">

          </slot>
        </div>
      </div>
    <img class="icon-background" src="@/assets/images/svg/robot_login.png" alt="">
  </div>
</template>

<script>
export default {
  name: "authLayout"
}
</script>

<style lang="scss" scoped>
:deep(.form__submit) .login-button {
  width: 300px;

  &-wrapper {
  }
}

:deep(.form__title) .title-yellow {
  color: #FFCE38;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 55px;
  margin-bottom: 100px;
}

.form {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  &__title {
    font-weight: 800;
    width: 300px;
    font-size: 40px;
    line-height: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 50px;
  }


  &__submit {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px;
    font-weight: 800;
  }
  &__download {
    display: flex;
    gap: 5px;
    margin: 0 auto;
    margin-top: 30px;
    bottom: 20px;
  }
}

.input-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

:deep(.input-list) .input-component {
  width: 300px;
}

:deep(.form) .additional-link {
  cursor: pointer;

  width: 300px;
  color: #C6C6C6;
  margin: 10px 0;
  text-align: right;

  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

:deep(.takeout-layout) .container-page {
  max-width: none;
  width: auto;
}
.icon-background {
  height: 100vh;
  width: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  @media (max-width: 1200px) {
    display: none;
  }
}
body {
  overflow: hidden;
}
@media (max-width: 400px) {
  .form {
    &__title {
      font-size: 36px;
      gap: 10px;
    }
  }
  .logo {
    margin: 30px 0;
    img {
      width: 120px;
    }
  }

}
</style>