import dictionaries from "@/modules/dictionaries/views/Dictionaries.vue";
import workshops from "@/modules/dictionaries/views/Workshops.vue";
import kitTypes from "@/modules/dictionaries/views/KitTypes.vue";
import classrooms from "@/modules/dictionaries/views/Classrooms.vue";
import grades from "@/modules/dictionaries/views/Grades.vue";
import itemTypes from "@/modules/dictionaries/views/ItemTypes.vue";

export const dictionariesRoutes = [
    {
        path: "/admin/dictionaries",
        component: dictionaries,
        meta: { isPublic: false }
    },
    {
        path: "/admin/dictionaries/workshops",
        component: workshops,
        meta: { isPublic: false }
    },
    {
        path: "/admin/dictionaries/kittypes",
        component: kitTypes,
        meta: { isPublic: false }
    },
    {
        path: "/admin/dictionaries/classrooms",
        component: classrooms,
        meta: { isPublic: false }
    },
    {
        path: "/admin/dictionaries/grades",
        component: grades,
        meta: { isPublic: false }
    },
    {
        path: "/admin/dictionaries/itemtypes",
        component: itemTypes,
        meta: { isPublic: false }
    },
]

