<template>
    <!-- Список справочников -->    
    <div class="container-page">
        <title-page-component>
            <template v-slot:title>
                Справочники
            </template>
        </title-page-component>
        <ul class="dict_list">
            <li><router-link to="/admin/dictionaries/workshops">Группы</router-link></li>
            <li><router-link to="/admin/dictionaries/grades">Классы</router-link></li>
            <li><router-link to="/admin/dictionaries/classrooms">Кабинеты</router-link></li>
            <li><router-link to="/admin/dictionaries/itemtypes">Типы комплектующих</router-link></li>
            <li><router-link to="/admin/dictionaries/kittypes">Тип набора</router-link></li>
        </ul>
    </div>
</template>
<script>
import TitlePageComponent from "@/components/ui/TitlePageComponent.vue";

export default {
  name: "dictionaries",
  components: {
    TitlePageComponent,
  },
  data() {
    return {
    };
  },
};
</script>
<style scoped>
.dict_list {
    list-style: disc;
    margin-left: 40px;
}
</style>