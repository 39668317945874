import axios from "axios";
import { authAxios } from "@/common/axios";
import router from '@/router'
import {dateFormat} from "@/helpers/dateFormat";

export default {
    namespaced: true,
    state: () => ({
        user: JSON.parse(localStorage.getItem('user')),
        accountCurrent: JSON.parse(localStorage.getItem('accountCurrent')) || {},
        returnUrl: null,
        errorsLogin: '',
        isUserExists: true,
        isRecoveryInstructionsModal: false,
        recoveryErrorMessage: null
    }),
    mutations: {
        setUser(state, payload) {
            const { user, err } = payload
            state.user = user;
            if (user) {
                localStorage.setItem('token', user.token);
                localStorage.setItem('user', JSON.stringify(user));
                authAxios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
                router.push(state.returnUrl)
                state.errorsLogin = ''
            } else {
                localStorage.removeItem('token')
                localStorage.removeItem('user');
                state.errorsLogin =  err.response.data.error || 'Неверные учетные данные'
            }
        },
        setAccountCurrent(state, accountCurrent) {
            state.accountCurrent = accountCurrent
            if (accountCurrent) {
                localStorage.setItem('accountCurrent', JSON.stringify(accountCurrent))
            } else {
                localStorage.removeItem('accountCurrent')
            }
        },
        logout(state) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            state.user = null;
            state.profile = null;
            //todo signout
        },
        setReturnUrl(state, url) {
            state.returnUrl = url
        },
        setError(state) {
            state.errorsLogin = ''
        },
        setEditAccountCurrent(state, data) {
            state.accountCurrent = data.data
        },
        enableUserNotExistsMessage(state) {
            state.isUserExists = false
        },
        disableUserNotExistsMessage(state) {
            state.isUserExists = true
        },
        showRecoveryInstructionsModal(state) {
            state.isRecoveryInstructionsModal = true
        },
        hideRecoveryInstructionsModal(state) {
            state.isRecoveryInstructionsModal = false
        },
        setRecoveryErrorMessage(state, message) {
            state.recoveryErrorMessage = message
        },
        disableRecoveryErrorMessage(state) {
            state.recoveryErrorMessage = ''
        },
        loadingAccountCurrent(state, boolean) {
            state.loadingAccountCurrent = boolean
        }
    },

    getters: {
        isAuthenticated: state => {
            return state.user != null
        }
    },

    actions: {
        async login(context, data) {
            authAxios.post('auth/login', data, null)
                .then((res) => {
                    context.commit('setUser', { user: res.data, err: null})
                })
                .catch((err) => {
                    context.commit('setUser', { user: null, err: err})
                })
        },
        async getAccountCurrent(context) {
            context.commit('loadingAccountCurrent', true)
            await axios.get('account/current')
                .then(response => {
                    const data = response.data;
                    data.birthday = dateFormat(data.birthday)
                    data.grants.forEach(grant => {
                        grant.grantedDt = dateFormat(grant.grantedDt);
                        grant.deadlineDt = dateFormat(grant.deadlineDt);
                        if (grant.returnDt) {
                            grant.returnDt = dateFormat(grant.returnDt);
                        }
                    });
                    context.commit('setAccountCurrent', data);
                    context.commit('loadingAccountCurrent', false)
                })
                .catch(e => console.log(e))

        },
        disableUserNotExistMessage(context) {
            context.commit('disableUserNotExistsMessage')
        },
        disableRecoveryInstructionsModal(context) {
            context.commit('hideRecoveryInstructionsModal')
        },
        disableRecoveryErrorMessage(context) {
            context.commit('disableRecoveryErrorMessage')
        },
        async getEditAccountCurrent(context, data) {
            context.commit('setEditAccountCurrent', data)
        },
        logout(context) {
            context.commit('logout')
        },
        setReturnUrl(context, url) {
            context.commit('setReturnUrl', url)
        },
        async resetPass(context, data) {
            authAxios.get('auth/PasswordRecovery/ForgotPassword', {
                params: {
                    username: data,
                }
            })
                .then(() => {
                    context.commit('showRecoveryInstructionsModal')
                    context.commit('disableUserNotExistsMessage')
                })
                .catch(() => {
                    context.commit('setRecoveryErrorMessage', 'Пользователя с такими данными не существует')
                    context.commit('enableUserNotExistsMessage')
                })

        },
        removeError(context) {
            context.commit('setError')
        },
        async changePass(context, data) {
           await authAxios.put('/auth/PasswordRecovery/ChangePassword', data)
               .then(res => {
                   if (res.data.succeeded) {
                       context.commit('showRecoveryInstructionsModal')
                       context.commit('disableUserNotExistsMessage')
                   }
                   else {
                       context.commit('setRecoveryErrorMessage', [res.data?.errors[0].description])
                       context.commit('enableUserNotExistsMessage')
                   }
                   if (res.data.errors) {
                       context.commit('setRecoveryErrorMessage', res.data.errors)
                   }
               })
               .catch(error => {
               if (error.response) {
                   context.commit('setRecoveryErrorMessage', Object.values(error.response.data.errors).flat())
                   context.commit('enableUserNotExistsMessage')
               }
           })
        },
    }
}