import { dateFormat } from "@/helpers/dateFormat";

export function handleItemData(response) {
  const data = response.data.items.map((item) => {
    return {
      id: item.id,
      kitId: item.kit?.id,
      data: {
        id: item.id,
        code: item.code,
        itemType: item.itemTypeTitle,
        kitType: item.kit?.code,
        feature: item.kit?.feature,
        grantedTo: item.kit?.grant?.grantedTo,
        grantedDt: dateFormat(item.kit?.grant?.grantedDt),
        deadlineDt: dateFormat(item.kit?.grant?.deadlineDt),
        state: item.stateTitle,
      },
    };
  });

  return {
    totalCount: response.data.totalCount,
    data: data,
    count: data.length,
  };
}
