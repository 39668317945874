<template>
  <div
    @click="!clickOutsideDisabled ? $emit('closeModal') : ''"
    class="modal-window"
  >
    <div
      @click.stop
      class="modal-window__dialog border-4 border-main ring-8 ring-main/20"
      :style="[`padding: 15px ${sidePadding}px; min-width: ${minWidth}px`]"
    >
      <div
        v-if="!noCloseCross"
        @click="$emit('closeModal')"
        class="button-back"
      >
        <img src="@/assets/images/svg/close-modal.svg" alt="" />
      </div>
      <div
        class="modal-head"
        :style="[
          contentCentered
            ? `justify-content: center`
            : `justify-content: space-between`,
        ]"
      >
        <div class="modal-title">
          <slot name="title"></slot>
        </div>
      </div>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "modalComponent",
  props: {
    sidePadding: {
      type: Number,
    },
    minWidth: {
      type: Number,
    },
    noCloseCross: {
      type: Boolean,
      default: false,
    },
    clickOutsideDisabled: {
      type: Boolean,
      default: false,
    },
    contentCentered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contentContainer: document.querySelector('.content')
    }
  },
  mounted() {
    document.body.style.overflow = "hidden";
    if (this.contentContainer) {
      this.contentContainer.style.paddingRight = "15px"
    }
  },
  unmounted() {
    document.body.style.overflow = "";
    if (this.contentContainer) {
      this.contentContainer.style.padding = "0"
    }
  },
};
</script>
<style lang="scss" scoped>
:deep(.modal-window__dialog) .line {
  max-width: 50px;
}
.modal-window {
  color: #4f4f4f;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  position: fixed;
  padding: 20px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-window__dialog {
  display: flex;
  flex-direction: column;
  padding: 15px 40px 40px 40px;
  z-index: 101;
  margin: 0 auto;
  position: relative;
  max-height: 100%;
  max-width: 800px;
  background-color: white;
  border-radius: 15px;
}
.button-back {
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: cover;
    height: 20px;
    width: 20px;
  }
}
.modal-head {
  margin-top: 10px;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  align-items: center;
}
.modal-title {
  font-size: 24px;
  font-weight: 800;
  max-width: 90%;
}
</style>
