<template>
   <div class="about container-page">
     <title-page-component>
      <template v-slot:title>
       Отчетность
      </template>
    </title-page-component> 
    <div class="relative w-72 m-auto">
         <img src="@/assets/images/kit-robot.png" alt="kit" class="absolute h-[495px] w-[500px] !max-w-[500px] top-[-40px] right-full"/>
      </div>
       <div class="gap-16 flex flex-col w-72 m-auto">
        
         <takeout-layout takeout="left" >
            <input-field class="input-component w-48" type="date" v-model:value="date" placeholder="" />
           </takeout-layout>
           <div class="gap-4 flex flex-col">
           <checkbox id="checkbox1"
      @change="handleCheckboxEquipChange" label="Отчет по оборудованию" :is-checked="withEquip" />
           <checkbox label="Отчет по пользователям" :is-checked="withUsers" @change="handleCheckboxUsersChange" />
         </div>
         <takeout-layout takeout="right">
             <button-component :is-disabled="isSubmitButtonDisabled" @click="onClickDownloadReport">
               Сформировать
             </button-component>
           </takeout-layout>
       </div>
   </div>
 </template>

 <script>
import TitlePageComponent from "@/components/ui/TitlePageComponent.vue";
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import InputField from "@/components/ui/InputField.vue";
import Checkbox from "@/components/ui/Checkbox.vue";
import axios from "axios";

 export default {
   name: "Report",
   components: {
     TitlePageComponent,
     TakeoutLayout,
     ButtonComponent,
     InputField,
     Checkbox
   },
   methods: {
      handleCheckboxEquipChange(newValue) {
        if (this.withUsers) {
          this.withUsers = false
        }
      this.withEquip = newValue;
      },
      handleCheckboxUsersChange(newValue) {
        if (this.withEquip) {
          this.withEquip = false
        }
      this.withUsers = newValue;
      },

      async onClickDownloadReport() {
        let currentURL = this.withEquip
                          ? `/Main/Kit/ReportByDate?dt=${this.date}+T00:00:00.000`
                          : `/User/ReportByDate?dt=${this.date}+T00:00:00.000`

        if (this.date) {
        let { data } = await axios.get(currentURL,{
           headers: {
             Authorization: axios.defaults.headers.common.Authorization
           }
          })
          try {
            const base64String = data.content;
            const binaryString = atob(base64String);
            const bytes = new Uint8Array(binaryString.length);

            for (let i = 0; i < binaryString.length; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }

            const blob = new Blob([bytes], { type: "application/octet-stream" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');

            a.href = url;
            a.download = `${data.fileName}`;

            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }
          catch(e) {
            console.error(e)
          }
        }
      }
   },
   data() {
     return {
      date: '',
      withUsers: false,
      withEquip: false
     }
   },
   computed: {
     isSubmitButtonDisabled() {
       return !this.date || (!this.withEquip && !this.withUsers)
     }
   }
 };
 </script>

 <style scoped lang="scss">

</style>