<template>
  <div class="container-page">
    <title-page-component>
      <template v-slot:title>
        <span v-if="this.$route.query.id"> Редактирование </span>
        <span v-else>Добавление</span>
        <span class="title-span"> пользователя </span>
      </template>
    </title-page-component>
    <div class="create-user">
      <div class="create-user__form-list">
        <takeout-layout takeout="left">
          <input-field
            class="input-component"
            v-model:value="newUser.surname"
            label="Фамилия"
            :label-error="validated.surname"
            type="text"
          />
        </takeout-layout>
        <takeout-layout takeout="left">
          <input-field
            class="input-component"
            v-model:value="newUser.name"
            label="Имя"
            :label-error="validated.name"
            type="text"
          />
        </takeout-layout>
        <takeout-layout takeout="left">
          <input-field
            class="input-component"
            v-model:value="newUser.patronymic"
            label="Отчество"
            type="text"
          />
        </takeout-layout>
        <div class="create-user__form-item">
          <takeout-layout takeout="left">
            <input-field
              class="input-component"
              v-model:value="newUser.birthday"
              label="Дата рождения"
              type="date"
              :max-date="currentDate"
              :label-error="validated.birthday"
            />
          </takeout-layout>
          <takeout-layout takeout="left">
            <input-field
              class="input-component"
              v-model:value="newUser.phoneNumber"
              label="Телефон"
              type="tel"
              :label-error="validated.phone"
            />
          </takeout-layout>
        </div>
        <takeout-layout takeout="left">
          <input-field
            class="input-component"
            v-model:value="newUser.email"
            label="Email"
            type="email"
            :label-error="validated.email"
          />
        </takeout-layout>
        <select-component
          takeout="left"
          class="input-component"
          :options="userGroup"
          v-model:selected-option="isEditUserGroup"
          label="Роль"
          type="text"
          :label-error="validated.userGroup"
        />
        <div class="create-user__form-item">
          <select-component
            takeout="left"
            class="input-component"
            :options="grades"
            v-model:selected-option="isEditGrade"
            label="Класс"
          />
          <select-component
            takeout="left"
            class="input-component"
            :options="workshops"
            :is-multi-select="true"
            v-model:selected-option="isEditWorkshops"
            label="Группы"
          />
        </div>
      </div>
      <div class="create-user__form-list">
        <div class="create-user__form-item">
          <div class="load-photo">
            <img
              :src="newUser.image"
              alt="Загруженное фото"
              v-if="newUser.image"
            />
            <img v-else src="@/assets/images/avatar.png" alt="" />
          </div>
          <takeout-layout>
            <input-field
              class="load-photo__button"
              @photoUrl="loadPhoto"
              label="Загрузить фото"
              type="file"
            >
              Загрузить фото
            </input-field>
          </takeout-layout>
        </div>
        <takeout-layout>
          <input-field
            class="input-component"
            v-model:value="newUser.comment"
            label="Коммент"
            type="textarea"
            max-length="100"
          />
        </takeout-layout>
        <takeout-layout class="flex-end">
          <button-component @click="createUser">
            <span v-if="this.$route.query.id"> Редактировать</span>
            <span v-else>Добавить</span>пользователя
          </button-component>
        </takeout-layout>
      </div>
    </div>
  </div>
  <modal-component @close-modal="this.errorModal = null" v-if="this.errorModal">
    <template #title>
      Ошибка!
    </template>
    <template #content>
      {{ this.errorModal }}
    </template>
  </modal-component>
  <modal-component v-if="successSubmit" @close-modal="this.$router.push('/registerUsers')">
      <template #title>
        Успешно!
      </template>
    <template #content>
      <div class="max-w-[250px]">
        <p>
          {{ this.$route.query?.id ? 'Вы успешно изменили данные пользователя!' : 'Вы добавили нового пользователя!' }}
        </p>
        <p v-if="commentModal || warningModal" class="mt-3">
          <span class="font-bold">
          {{ this.commentModal + ':' }}
            </span>
          {{ this.warningModal }}
        </p>
      </div>
      <takeout-layout class="mt-4">
        <button-component @click="this.$router.push('/registerUsers')" >
          Продолжить
        </button-component>
      </takeout-layout>
    </template>
  </modal-component>
</template>

<script>
import TitlePageComponent from "@/components/ui/TitlePageComponent.vue";
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import InputField from "@/components/ui/InputField.vue";
import SelectComponent from "@/components/ui/SelectComponent.vue";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import axios from "axios";
import { checkAllFieldsEmpty, checkFieldEmpty } from "@/helpers/validate";
import { dateFormat } from "@/helpers/dateFormat";
import ModalComponent from "@/components/ui/ModalComponent.vue";

export default {
  name: "CreatUser",
  components: {
    ModalComponent,
    ButtonComponent,
    SelectComponent,
    InputField,
    TakeoutLayout,
    TitlePageComponent,
  },
  data() {
    return {
      newUser: {
        surname: "",
        name: "",
        patronymic: "",
        workshops: [1],
        gradeId: null,
        email: "",
        phoneNumber: "",
        positionId: null,
        userGroupId: null,
        birthday: null,
        comment: "",
        image: "",
      },
      isEditUserGroup: null,
      isEditGrade: null,
      isEditWorkshops: null,

      selectedFile: null,

      isLoadPhoto: null,

      successSubmit: false,
      errorModal: null,
      commentModal: null,
      warningModal: null,

      validated: {
        surname: "",
        name: "",
        email: "",
        birthday: "",
        phone: "",
        userGroup: "",
        grade: "",
      },
    };
  },
  methods: {
    loadPhoto(url) {
      this.newUser.image = url;
    },
    createUser() {
      this.newUser.gradeId = this.isEditGrade?.id;
      this.newUser.userGroupId = this.isEditUserGroup?.id;
      this.newUser.workshops = this.isEditWorkshops?.map(
        (workshop) => workshop?.id,
      );

      this.checkedValidated();
      if (this.isValidate) {
        if (this.$route.query?.id) {

          axios
            .put("/User/" + this.$route.query?.id, this.newUser)
            .then(() => {
              this.successSubmit = true
              this.$store.dispatch("auth/getAccountCurrent");
            })
            .catch((er) => {
              this.errorModal = er.response.data?.errors[0] || er.response.data?.errors?.data[0] || 'Неизвестная ошибка';
            });
        } else {

          axios
            .post("/User", this.newUser)
            .then((res) => {
              this.commentModal = res.data.comment
              this.warningModal = res.data.warning
              this.successSubmit = true
              this.$store.dispatch("auth/getAccountCurrent");
            })
            .catch((er) => {
              this.errorModal = er.response.data?.errors[0] || er.response.data?.errors?.data[0] || 'Неизвестная ошибка';
            });
        }
      }
    },
    checkedValidated() {
      const v = this.validated;
      const form = this.newUser;

      const messRequiredField = "Обязательное поле";
      const messOnlyLetters = "Не используйте цифры или спец. символы";
      const messEmail = "Введите верный формат почты";
      const messPhone = "Номер должен содержать 11 цифр";

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const onlyLettersRegex = /^[a-zA-Zа-яА-Я]+$/;

      if (!form.email?.length) {
        v.email = messRequiredField;
      } else if (!emailRegex.test(form.email)) {
        v.email = messEmail;
      } else {
        v.email = "";
      }

      if (!form.surname?.length) {
        v.surname = messRequiredField;
      } else if (!onlyLettersRegex.test(form.surname)) {
        v.surname = messOnlyLetters;
      } else {
        v.surname = "";
      }

      if (!form.name?.length) {
        v.name = messRequiredField;
      } else if (!onlyLettersRegex.test(form.surname)) {
        v.name = messOnlyLetters;
      } else {
        v.name = "";
      }

      if (!(form?.phoneNumber?.length === 16)) {
        v.phone = messPhone;
      } else {
        v.phone = "";
      }

      if (!form.userGroupId) {
        v.userGroup = messRequiredField;
      } else {
        v.phone = "";
      }
      v.birthday = checkFieldEmpty(form.birthday);
    },
  },
  computed: {
    isValidate() {
      return checkAllFieldsEmpty(this.validated);
    },
    grades() {
      return this.$store.state.handbooks.grade;
    },
    workshops() {
      return this.$store.state.handbooks.workshop;
    },
    userGroup() {
      return this.$store.state.handbooks.userGroup;
    },
    currentDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
  },
  mounted() {
    this.$store.dispatch("handbooks/getGrade");
    this.$store.dispatch("handbooks/getWorkshop");
    if (this.$route?.query?.id) {
      axios.get("/User/" + this.$route?.query?.id).then((res) => {
        const user = this.newUser;
        const response = res.data;
        user.surname = response.surname;
        user.name = response.name;
        user.patronymic = response.patronymic;
        const workshopTitles = response.workshops
          ?.split(",")
          .map((title) => title.trim());
        this.isEditWorkshops = this.workshops?.filter((workshop) =>
          workshopTitles?.includes(workshop.title),
        );
        user.workshops = this.isEditWorkshops?.map((workshop) => workshop.id);
        this.isEditGrade = this.grades?.find(
          (item) => item.title === response.grade,
        );
        user.gradeId = this.isEditGrade?.id;
        user.email = response.email;
        user.phoneNumber = response.phoneNumber;
        this.isEditUserGroup = this.userGroup?.find(
          (item) => item.code === response.userGroup,
        );
        user.userGroupId = this.isEditUserGroup?.id;
        user.birthday = dateFormat(response.birthday, true);
        user.comment = response.comment;
        user.image = response.image;
      });
    }
  },
};
</script>
<style scoped lang="scss">
.title-span {
  font-weight: 700;
  color: #ffce38;
}
.create-user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 70px;
  &__form-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 500px;
  }
  &__form-item {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    .input-component {
      width: 230px;
    }
  }
}
.input-component {
  width: 100%;
}
.load-photo {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  border: 10px solid #ffce38;
  img {
    width: auto;
    height: 100%;
  }
  &__button {
    width: 100%;
  }
}
.load-photo {
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
.flex-start {
  align-self: flex-start;
}
.flex-end {
  align-self: flex-end;
}
</style>