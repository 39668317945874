import api from "@/common/api/api";
import route from '@/router'

export default {
    namespaced: true,
    state: () => ({
       itemData: () => {},
    }),
    actions: {
        loadItemInfo(context) {
            api.getItemInfo(route.currentRoute.value.params.id).then((res) => {
                    context.commit("setItemInfo", res.data)
            });
        }
    },
    mutations: {
        setItemInfo(state, itemData) {
            state.itemData = itemData
        }
    },
}