<template>
  <div class="container-page">
    <title-page-component>
      <template v-slot:title>
        Реестр <span class="title-span">пользователей</span>
      </template>
      <template v-slot:button>
        <takeout-layout takeout="right">
          <button-component @click="onClickExport" class="upload-button"
            >Выгрузить
            <template #icon-left>
              <img src="@/assets/images/svg/button-unload.svg" alt="" />
            </template>
          </button-component>
        </takeout-layout>
      </template>
    </title-page-component>
    <register-table
      @edit="editUser"
      @clear-filters="clearFilters"
      @create="createUser"
      @delete="deleteKit"
      @search="searchKit"
      @upload-register="uploadRegister"
      @sort="sort"
      :total-count="totalCount"
      :current-count="currentCount"
      :register="register"
      :register-title="registerTitle"
      :is-register="'user'"
      :is-loading="isLoading"
    >
      <template v-slot:filters>
        <div class="filters">
          <div class="filters__toggle" @click="filtersToggle">
            <div>{{ !isfiltersToggle ? "Показать" : "Скрыть" }} фильтры</div>
            <img
              :class="{ 'rotate-180': isfiltersToggle }"
              src="@/assets/images/svg/arrow.svg"
              alt=""
            />
          </div>
          <div class="filters__row" v-if="isfiltersToggle">
            <div class="filters__selectors">
              <takeout-layout takeout="left">
                <input-field
                  label="Дата рождения"
                  v-model:value="filterBirthday"
                  type="date"
                  class="filters__selector"
                />
              </takeout-layout>
                <select-component
                    takeout="left"
                  :options="userGroup"
                  v-model:selected-option-id="filterUserGroup"
                  label="Роль"
                  class="filters__selector"
                />
                <select-component
                    takeout="left"
                  :options="workshops"
                  v-model:selected-option-id="filterWorkshops"
                  label="Группа"
                  class="filters__selector"
                />
            </div>
            <div class="filters__apply">
              <div class="flex gap-[20px]">
                <takeout-layout>
                  <button-component class="filters__selector" @click="getUsers">
                    Применить
                  </button-component>
                </takeout-layout>
                <takeout-layout>
                  <button-component class="filters__selector" @click="clearFilters">
                    Сбросить фильтр
                    <template #icon-left>
                      <img src="@/assets/images/svg/button-update.svg" alt="" />
                    </template>
                  </button-component>
                </takeout-layout>
              </div>
            </div>
          </div>
        </div>
      </template>
    </register-table>
  </div>
</template>

<script>
import TitlePageComponent from "@/components/ui/TitlePageComponent.vue";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import RegisterTable from "@/modules/registers/RegisterTable.vue";
import axios from "axios";
import { handleUserData } from "@/modules/registers/helpers/handle-user-data";
import SelectComponent from "@/components/ui/SelectComponent.vue";
import InputField from "@/components/ui/InputField.vue";
import utils from "@/common/utils";

export default {
  name: "registerUsers",
  components: {
    InputField,
    SelectComponent,
    RegisterTable,
    TakeoutLayout,
    ButtonComponent,
    TitlePageComponent,
  },
  data() {
    return {
      isLoading: false,

      register: [],
      registerTitle: [
        {
          code: "fullName",
          title: "ФИО",
          width: "195px",
        },
        {
          code: "birthday",
          title: "Дата рождения",
          width: "120px",
        },
        {
          code: "userGroup",
          title: "Роль",
          width: "160px",
        },
        {
          code: "workshops",
          title: "Группа",
          width: "100px",
        },
        {
          code: "phoneNumber",
          title: "Телефон",
          width: "150px",
        },
        {
          code: "email",
          title: "Email",
          width: "180px",
        },
        {
          code: "comment",
          title: "Дополнительные сведения",
          width: "170px",
        },
        {
          code: "isDeleted",
          title: "Статус",
          width: "95px",
        },
      ],
      searchValue: "",

      pageKit: 1,
      totalCount: null,
      currentCount: null,

      titleSort: "",
      directionSort: null,

      isfiltersToggle: false,

      filterUserGroup: "",
      filterWorkshops: "",
      filterBirthday: "",
    };
  },
  methods: {
    editUser(id) {
      this.$router.push({ path: "/createUser", query: { id: id } });
    },
    createUser() {
      this.$router.push("/createUser");
    },
    async getUsers() {
      this.isLoading = true;
      try {
        this.pageKit = 1;
        const response = await axios.post("/User/Search", {
          page: this.pageKit,
          count: 10,
          search: {
            text: this.searchValue || "",
            fieldNames: [],
            allTextFields: true,
            nonStrictMatch: true,
          },
          sortItems: [
            {
              fieldName: this.titleSort || "id",
              direction: this.directionSort || 0,
            },
          ],
          filterItems: [
            {
              fieldName: "userGroup",
              condition: 0,
              value: this.filterUserGroup,
            },
            {
              fieldName: "workshops",
              condition: 0,
              value: this.filterWorkshops,
            },
            {
              fieldName: "birthday",
              condition: 0,
              value: this.filterBirthday,
            },
          ],
        });
        const { totalCount, data, count } = handleUserData(response);
        this.totalCount = totalCount;
        this.register = data;
        this.currentCount = count;
        this.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async subloadUsers() {
      this.isLoading = true;
      try {
        const response = await axios.post("/User/Search", this.requestParams);
        const { totalCount, data, count } = handleUserData(response);
        this.totalCount = totalCount;
        this.register.push(...data);
        this.currentCount += count;
        this.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    },
    clearFilters() {
      this.filterBirthday = "";
      this.filterUserGroup = "";
      this.filterWorkshops = "";
    },
    uploadRegister() {
      this.pageKit += 1;
      this.subloadUsers();
    },

    creatRegister() {
      axios
        .post("/User", this.newRegister)
        .then(() => {
          this.$emit("updateRegister");
        })
        .catch((err) => {
          console.log(err);
        });
      this.isCreatRegister = false;
    },

    searchKit(searchValue) {
      this.titleSort = "";
      this.directionSort = null;
      this.searchValue = searchValue;
      this.getUsers();
    },
    sort(code) {
      this.titleSort = code;
      this.directionSort === 1
        ? (this.directionSort = 0)
        : (this.directionSort = 1);
      this.getUsers();
    },
    async deleteKit(id) {
      try {
        await axios.delete("/User/" + id).then(() => {
          this.getUsers();
        });
      } catch (e) {
        console.log(e);
      }
    },
    filtersToggle() {
      this.isfiltersToggle = !this.isfiltersToggle;
    },
    onClickExport() {
      let currentURL = `/user/export`;
      utils.downloadFileExport(currentURL, this.requestParams);
    },
  },
  mounted() {
    this.getUsers();
    this.$store.dispatch("handbooks/getWorkshop");
  },
  computed: {
    userGroup() {
      return this.$store.state.handbooks.userGroup;
    },
    workshops() {
      return this.$store.state.handbooks.workshop;
    },
    requestParams() {
      return {
        page: this.pageKit,
        count: 10,
        search: {
          text: this.searchValue || "",
          fieldNames: [
            "id",
            "fullName",
            "birthday",
            "userGroup",
            "workshops",
            "phoneNumber",
            "email",
            "comment",
          ],
          allTextFields: false,
          nonStrictMatch: true,
        },
        sortItems: [
          {
            fieldName: this.titleSort || "id",
            direction: this.directionSort || 0,
          },
        ],
        filterItems: [
          {
            fieldName: "userGroup",
            condition: 0,
            value: this.filterUserGroup,
          },
          {
            fieldName: "workshops",
            condition: 0,
            value: this.filterWorkshops,
          },
          {
            fieldName: "birthday",
            condition: 0,
            value: this.filterBirthday,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-button {
  width: 220px;
}

.title-span {
  font-weight: 700;
  color: #ffce38;
}
.modal-register {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.input-component {
  width: 300px;
}

.filters {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
  &__toggle {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    gap: 15px;
    cursor: pointer;
  }
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__apply {
    align-self: flex-end;
  }
  &__selector {
    width: 100%;
    min-width: 240px;
  }
  &__selectors {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
}
.rotate-180 {
  transform: rotate(180deg);
}
</style>
