<template>
  <div class="title-page">
      <takeout-layout v-if="routBackView" takeout="left">
        <router-link to="/">
          <div @click="routBack()" class="button-back">
            <img src="@/assets/images/svg/arrow_back.svg" alt="">
          </div>
        </router-link>
      </takeout-layout>
    <div class="input-additional">
      <slot name="input">

      </slot>
    </div>
    <h1 class="title">
      <slot name="title"></slot>

    </h1>
    <div class="button-additional">
      <slot name="button">

      </slot>
    </div>
  </div>
</template>

<script>
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
export default {
  name: "titlePageComponent",
  components: {TakeoutLayout},
  props: {
    routBackView: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    routBack() {
      this.$router.go(-1);
    }
  }
}
</script>

<style lang="scss" scoped>
.title-page {
  margin: 45px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
}
.title {
  flex: 1;
  justify-self: flex-start;
  font-size: 40px;
  line-height: 55px;
  font-weight: 400;
}
.button-back {
  min-width: 78px;
  height: 78px;
  border: 10px solid #FFCE38;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  img {
    border-radius: 40px;
    object-fit: cover;
    height: 35px;
    width: 35px;
  }
}
</style>