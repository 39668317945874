<template>
  <div class="container-page">
    <title-page-component>
      <template v-slot:title>
        Реестр <span class="title-span">комплектующих</span>
      </template>
      <template v-slot:button>
        <takeout-layout takeout="right">
          <button-component class="upload-button" @click="onClickExport"
            >Выгрузить
            <template #icon-left>
              <img src="@/assets/images/svg/button-unload.svg" alt="" />
            </template>
          </button-component>
        </takeout-layout>
      </template>
    </title-page-component>
    <register-table
      @selected-items="getSelectedItems"
      @edit="openModalEdit"
      @clear-filters="clearFilters"
      @create="openModalCreate"
      @delete="deleteKit"
      @search="searchKit"
      @upload-register="uploadRegister"
      @sort="sort"
      @togo="togo"
      :total-count="totalCount"
      :current-count="currentCount"
      :register="register"
      :register-title="registerTitle"
      :is-register="'parts'"
      :is-loading="isLoading"
    >
      <template v-slot:filters>
        <div class="filters">
          <div class="filters__toggle" @click="filtersToggle">
            <div>{{ !isFiltersToggle ? "Показать" : "Скрыть" }} фильтры</div>
            <img
              :class="{ 'rotate-180': isFiltersToggle }"
              src="@/assets/images/svg/arrow.svg"
              alt=""
            />
          </div>
          <div class="filters__form" v-if="isFiltersToggle">
            <div class="filters__row">
              <div class="filters__selectors">
                  <select-component  takeout="left"
                    :options="typeItem"
                    v-model:selected-option-id="filtersItemType"
                    label="Тип"
                    class="filters__selector"
                  />
                  <select-component takeout="left"
                    :options="kits"
                    v-model:selected-option-id="filtersKit"
                    label="Набор"
                    class="filters__selector"
                  />
                  <select-component takeout="left"
                    :options="users"
                    v-model:selected-option-id="filtersGrantedTo"
                    label="Кому выдан"
                    class="filters__selector"
                  />
                <takeout-layout takeout="left">
                  <input-field
                    label="Дата изменения"
                    v-model:value="filtersGrantedDt"
                    type="date"
                    class="filters__selector"
                  />
                </takeout-layout>
              </div>
            </div>
            <div class="filters__row">
              <div class="filters__selectors">
                <takeout-layout takeout="left">
                  <input-field
                    label="Срок сдачи"
                    v-model:value="filtersDeadlineDt"
                    type="date"
                    class="filters__selector"
                  />
                </takeout-layout>
                  <select-component takeout="left"
                    :options="stateItem"
                    v-model:selected-option-id="filtersState"
                    label="Статус"
                    class="filters__selector"
                  />
                <div class="filters__apply">
                  <div class="flex gap-[20px]">
                    <takeout-layout>
                      <button-component
                        title="Применить фильтрацию"
                        class="filters__selector"
                        @click="getItem"
                      >
                        Применить
                      </button-component>
                    </takeout-layout>
                    <takeout-layout>
                      <button-component
                        title="Очистить списки фильтрации"
                        class="filters__selector"
                        @click="clearFilters"
                      >
                        Сбросить фильтр
                        <template #icon-left>
                          <img
                            src="@/assets/images/svg/button-update.svg"
                            alt=""
                          />
                        </template>
                      </button-component>
                    </takeout-layout>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </register-table>
    <div class="modals">
      <modal-component
        @close-modal="this.isCreatRegister = !this.isCreatRegister"
        v-if="isCreatRegister"
      >
        <template v-slot:title>Добавить комплектующее</template>
        <template v-slot:content>
          <div class="modal-register" @click.stop>
            <takeout-layout>
              <input-field
                class="input-component"
                v-model:value="newRegister.code"
                :label-error="validatedCreateForm.code"
                label="Наименование"
                type="text"
                max-length="36"
              />
            </takeout-layout>
            <takeout-layout>
              <select-component
                class="input-component"
                v-model:selected-option-id="newRegister.itemTypeId"
                :label-error="validatedCreateForm.itemTypeId"
                :options="typeItem"
                label="Тип комплектующего"
                type="text"
              />
            </takeout-layout>
            <takeout-layout>
              <select-component
                class="input-component"
                v-model:selected-option-id="newRegister.stateId"
                :label-error="validatedCreateForm.stateId"
                :options="stateItem"
                label="Статус"
                type="text"
              />
            </takeout-layout>
            <takeout-layout>
              <select-component
                class="input-component"
                v-model:selected-option-id="newRegister.kitId"
                :label-error="validatedCreateForm.kitId"
                :options="kits"
                label="Набор"
                type="text"
              />
            </takeout-layout>
            <takeout-layout>
              <button-component @click="creatRegister">
                Сохранить
                <template #icon-left>
                  <img src="@/assets/images/svg/button-add.svg" alt="" />
                </template>
              </button-component>
            </takeout-layout>
          </div>
        </template>
      </modal-component>
      <modal-component
        @close-modal="this.isEditRegister = !this.isEditRegister"
        v-if="isEditRegister"
      >
        <template v-slot:title>Редактировать</template>
        <template v-slot:content>
          <div class="modal-register" @click.stop>
            <takeout-layout>
              <input-field
                v-model:value="editRegister.code"
                :label-error="validatedEditForm.code"
                class="input-component"
                label="Наименование"
                type="text"
                max-length="36"
              />
            </takeout-layout>
            <takeout-layout>
              <select-component
                v-model:selected-option="editSelectItemType"
                :label-error="validatedEditForm.itemTypeId"
                :options="typeItem"
                class="input-component"
                label="Тип комплектующего"
                type="text"
              />
            </takeout-layout>
            <takeout-layout>
              <select-component
                v-model:selected-option="editSelectState"
                :label-error="validatedEditForm.stateId"
                :options="stateItem"
                class="input-component"
                label="Статус"
                type="text"
              />
            </takeout-layout>
            <takeout-layout>
              <button-component @click="editKit"> Сохранить </button-component>
            </takeout-layout>
          </div>
        </template>
      </modal-component>
    </div>

    <takeout-layout v-if="queryStatus" takeout="right" class="h-[75px]">
      <button-component max-width='350'
                        width="350"
                        class="upload-button ml-auto my-10"
                        :is-disabled="!selectedItems.length"
                        @click="onClickAddParts"
      >Добавить в набор
        <template #icon-left>
          <img src="@/assets/images/svg/button-unload.svg" alt="" />
        </template>
      </button-component>
    </takeout-layout>

    <takeout-layout v-if="queryStatus" takeout="right" class="h-[75px]">
      <button-component max-width='350'
                        width="350"
                        class="upload-button ml-auto my-10"
                        @click="onClickCancelAdd"
      >Отмена
      </button-component>
    </takeout-layout>

  </div>
</template>

<script>
import TitlePageComponent from "@/components/ui/TitlePageComponent.vue";
import ButtonComponent from "@/components/ui/ButtonComponent.vue";
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import RegisterTable from "@/modules/registers/RegisterTable.vue";
import axios from "axios";
import ModalComponent from "@/components/ui/ModalComponent.vue";
import InputField from "@/components/ui/InputField.vue";
import { handleItemData } from "@/modules/registers/helpers/handle-item-data";
import SelectComponent from "@/components/ui/SelectComponent.vue";
import utils from "@/common/utils";
import {checkAllFieldsEmpty, checkFieldEmpty} from "@/helpers/validate";

export default {
  name: "registerParts",
  components: {
    SelectComponent,
    RegisterTable,
    TakeoutLayout,
    ButtonComponent,
    TitlePageComponent,
    ModalComponent,
    InputField,
  },
  data() {
    return {
      isLoading: false,
      register: [],
      registerTitle: [
        {
          code: "id",
          title: "id",
          width: "65px",
        },
        {
          code: "code",
          title: "Наименование",
          width: "190px",
        },
        {
          code: "itemTypeId",
          title: "Тип",
          width: "110px",
        },
        {
          code: "kit.id",
          title: "Набор",
          width: "125px",
        },
        {
          code: "kit.feature",
          title: "Особенности",
          width: "175px",
        },
        {
          code: "kit.grant.grantedToId",
          title: "Кому выдан",
          width: "184px",
        },
        {
          code: "kit.grant.grantedDt",
          title: "Дата выдачи",
          width: "100px",
        },
        {
          code: "kit.grant.deadLineDt",
          title: "Срок сдачи",
          width: "100px",
        },
        {
          code: "state.title",
          title: "Статус",
          width: "100px",
        },
      ],
      searchValue: "",
      selectedItems: [],
      kitState: [],
      kitType: [],
      classroom: [],
      pageKit: 1,
      totalCount: null,
      currentCount: null,
      isCreatRegister: false,
      isEditRegister: false,

      newRegister: {
        code: "",
        itemTypeId: null,
        stateId: null,
        kitId: null,
      },

      editRegister: {
        code: "",
        itemTypeId: "",
        stateId: null,
        kitId: null,
      },

      validatedCreateForm: {
        code: '',
        itemTypeId: '',
        stateId: '',
      },

      validatedEditForm: {
        code: '',
        itemTypeId: '',
        stateId: '',
      },

      editSelectItemType: {},
      editSelectState: {},
      grantedSelectUser: null,
      idEditRegister: "",
      titleSort: "",
      directionSort: null,
      isGrantedKitID: null,
      isGrantedToID: null,
      isGrantedDeadlineDt: null,
      isValidateErrorCreatRegister: false,
      isFiltersToggle: false,

      filtersItemType: "",
      filtersKit: "",
      filtersGrantedTo: "",
      filtersGrantedDt: "",
      filtersDeadlineDt: "",
      filtersState: '',
    };
  },
  methods: {
    openModalEdit(id) {
      this.clearValidated()
      this.isEditRegister = !this.isEditRegister;
      axios.get("main/item/" + id).then((res) => {
        const response = res.data;
        this.idEditRegister = response.id;
        this.editRegister.code = response.code;
        this.editRegister.kitId = response.kitId;
        this.editSelectItemType = this.typeItem.find(
            (item) => item.title === response.itemTypeTitle,
        );
        this.editSelectState = this.stateItem.find(
            (item) => item.title === response.stateTitle,
        );
      });
    },
    setFilterState() {
      if (localStorage.getItem('selectKit') && JSON.parse(localStorage.getItem('selectKit')).mode === 'partsSelect') {
        this.filtersState = 3
      }
    },
    openModalCreate() {
      this.clearValidated()
      this.isCreatRegister = !this.isCreatRegister;

      this.newRegister.kitId = null
      this.newRegister.code = null
      this.newRegister.stateId = null
      this.newRegister.itemTypeId = null
    },
    async getItem() {
      this.isLoading = true;
      this.pageKit = 1;
      try {
        const response = await axios.post(
            "main/item/search",
            this.requestParams,
        );
        const {totalCount, data, count} = handleItemData(response);
        this.totalCount = totalCount;
        this.register = data;
        this.currentCount = count;
        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    async subloadKit() {
      this.isLoading = true;
      try {
        const response = await axios.post(
            "main/item/search",
            this.requestParams,
        );
        const {totalCount, data, count} = handleItemData(response);
        this.totalCount = totalCount;
        this.register.push(...data);
        this.currentCount += count;
        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    editKit() {
      this.editRegister.stateId = this.editSelectState.id;
      this.editRegister.itemTypeId = this.editSelectItemType.id;
      this.checkedValidatedEditForm()
      if (
        this.isValidateEditForm
      ) {
        axios
            .put("main/item/" + this.idEditRegister, this.editRegister)
            .then(() => {
              this.pageKit = 1;
              this.getItem();
            });
        this.isEditRegister = false;
        this.clearValidated()
      }
    },
    uploadRegister() {
      this.pageKit += 1;
      this.subloadKit();
    },
    getSelectedItems(kitIds) {
      this.selectedItems = kitIds
    },
    onClickAddParts() {
      let kitId = JSON.parse(localStorage.getItem('selectKit')).kit
      let params = {
        kit: JSON.parse(localStorage.getItem('selectKit')).kit,
        items: this.selectedItems,
        currentItems: JSON.parse(localStorage.getItem('selectKit')).currentItems ?
            JSON.parse(localStorage.getItem('selectKit')).currentItems :
            ''
      }
      localStorage.setItem('selectKit', JSON.stringify(params))
      this.$router.push({path: `/kit/${kitId}`})
    },
    onClickCancelAdd() {
      let kitId = JSON.parse(localStorage.getItem('selectKit')).kit
      localStorage.removeItem('selectKit')
      this.$router.push({path: `/kit/${kitId}`})
    },
    creatRegister() {
      this.checkedValidatedCreateForm()
      if (
        this.isValidateCreateForm
      ) {
        axios
            .post("main/item", this.newRegister)
            .then(() => {
              this.getItem();
            })
            .catch((err) => {
              console.log(err);
            });
        this.isCreatRegister = false;

        this.clearValidated()

        this.newRegister.code = "";
        this.newRegister.stateId = null;
        this.newRegister.itemTypeId = null;
        this.newRegister.kitId = null;
      } else {
        console.log('validate false')
      }
    },
    sort(code) {
      this.titleSort = code;
      this.directionSort === 1
          ? (this.directionSort = 0)
          : (this.directionSort = 1);
      this.getItem();
    },
    searchKit(searchValue) {
      this.titleSort = "";
      this.directionSort = null;
      this.searchValue = searchValue;
      this.getItem();
    },
    onClickExport() {
      let currentURL = `/Main/Item/export`;
      utils.downloadFileExport(currentURL, this.requestParams);
    },
    async deleteKit(id) {
      await axios.delete("main/item/" + id).then(() => {
        this.getItem();
      });
    },
    togo(id) {
      this.$router.push("item/" + id);
    },
    filtersToggle() {
      this.isFiltersToggle = !this.isFiltersToggle;
    },
    clearFilters() {
      this.filtersState = "";
      this.filtersDeadlineDt = "";
      this.filtersGrantedTo = "";
      this.filtersGrantedDt = "";
      this.filtersKit = "";
      this.filtersItemType = "";
    },
    checkedValidatedCreateForm() {
      const formCreate = this.newRegister
      const vc = this.validatedCreateForm
      vc.code = checkFieldEmpty(formCreate?.code)
      vc.itemTypeId = checkFieldEmpty(formCreate?.itemTypeId)
      vc.stateId = checkFieldEmpty(formCreate?.stateId)


    },
    checkedValidatedEditForm() {
      const formEdit = this.editRegister
      const ve = this.validatedEditForm

      ve.code = checkFieldEmpty(formEdit?.code)
      ve.itemTypeId = checkFieldEmpty(formEdit?.itemTypeId)
      ve.stateId = checkFieldEmpty(formEdit?.stateId)
    },
    clearValidated() {

      const vc = this.validatedCreateForm
      const ve = this.validatedEditForm

      vc.code = ''
      vc.itemTypeId = ''
      vc.stateId = ''
      ve.code = ''
      ve.itemTypeId = ''
      ve.stateId = ''
    },
  },
  created() {
    this.setFilterState()
  },
  mounted() {
    this.getItem();
    this.$store.dispatch("handbooks/getItemType");
    this.$store.dispatch("handbooks/getStateItem");
    this.$store.dispatch("kits/getKit");
    this.$store.dispatch("users/getUsers");
  },
  computed: {
    isValidateEditForm() {
      return checkAllFieldsEmpty(this.validatedEditForm)
    },
    isValidateCreateForm() {
      return checkAllFieldsEmpty(this.validatedCreateForm)
    },
    stateItem() {
      return this.$store.state.handbooks.stateItem;
    },
    typeItem() {
      return this.$store.state.handbooks.typeItem;
    },
    kits() {
      return this.$store.getters["kits/kitsForSelector"];
    },
    queryStatus() {
      return localStorage.getItem('selectKit') && JSON.parse(localStorage.getItem('selectKit')).mode === 'partsSelect'
    },
    users() {
      return this.$store.getters["users/usersForSelect"];
    },
    features() {
      return this.$store.getters["kits/kitFeaturesForSelector"];
    },
    requestParams() {
      return {
        page: this.pageKit,
        count: 10,
        search: {
          text: this.searchValue || "",
          fieldNames: [],
          allTextFields: true,
          nonStrictMatch: true,
        },
        sortItems: [
          {
            fieldName: this.titleSort || "id",
            direction: this.directionSort || 0,
          },
        ],

        filterItems: [
          {
            fieldName: "itemTypeId",
            condition: 0,
            value: this.filtersItemType,
          },
          {
            fieldName: "kit.grant.grantedToId",
            condition: 0,
            value: this.filtersGrantedTo,
          },
          {
            fieldName: "kit.grant.grantedDt",
            condition: 0,
            value: this.filtersGrantedDt,
          },
          {
            fieldName: "kit.grant.deadlineDt",
            condition: 0,
            value: this.filtersDeadlineDt,
          },
          {
            fieldName: "stateId",
            condition: 0,
            value: this.filtersState,
          },
          {
            fieldName: "kitId",
            condition: 0,
            value: this.filtersKit,
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-button {
  width: 220px;
}

.title-span {
  font-weight: 700;
  color: #ffce38;
}
.modal-register {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.input-component {
  width: 300px;
}

.filters {
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 30px;
  &__toggle {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    gap: 15px;
    cursor: pointer;
  }
  &__form {
    display: flex;
    gap: 30px;
    flex-direction: column;
  }
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__apply {
    align-self: flex-end;
    margin-left: auto;
  }
  &__selector {
    width: 240px;
    min-width: 240px;
  }
  &__selectors {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
  }
}
.rotate-180 {
  transform: rotate(180deg);
}
:deep(.modals) .modal-title {
  max-width: 300px;
}
</style>
