import axios from 'axios';
import runtimeConfig from '@/common/runtime-config';
import router from "@/router";

axios.interceptors.request.use(
  config => {
    config.params = {...config.params};
    refreshAuthorizationHeader();
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(function (response) {
  return response;
}, processError);

export function configAxios() {
  axios.defaults.baseURL = runtimeConfig.apiBaseUrl;
}

const baseAxios = axios.create({
  baseURL: runtimeConfig.apiBaseUrl
})
const authAxios = axios.create({
  baseURL: runtimeConfig.apiAuthUrl
});

export default function refreshAuthorizationHeader() {
  const token = localStorage.getItem('token');
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    authAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  } else {
    delete axios.defaults.headers.common['Authorization'];
    delete authAxios.defaults.headers.common['Authorization'];
  }
}
authAxios.interceptors.response.use(function (response) {
  return response;
}, processError);

function processError(error) {
  if (error?.response?.status === 401) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
      refreshAuthorizationHeader();
      router.push({ name: "ErrorPage"});
    } else
      if (router.currentRoute.value.name !== "ErrorPage")
        router.push({ name: "Logout"});
    return error?.response;
  }
  return Promise.reject(error);
}

refreshAuthorizationHeader();

export { authAxios, baseAxios }