<template>
  <div>
    <auth-layout>
      <template v-slot:title>
        <span class="title-yellow">Создать</span> <span>пароль</span>
      </template>
      <template v-slot:inputs>

        <div v-if="error">
          <p class="text-red-500 text-lg italic">{{ this.error?.description }}</p>
        </div>

        <takeout-layout>
          <input-field
              @input="validateNewPassword"
            class="input-component"
            @keydown.space="(event) => event.preventDefault()"
            v-model:value="this.newPassword"
            :labelError="validate.matchPass"
            label="Новый пароль"
            type="password"
          />
        </takeout-layout>
        <takeout-layout>
          <input-field
              @input="validateNewPasswordRepeat"
            class="input-component"
            @keydown.space="(event) => event.preventDefault()"
            v-model:value="this.newPasswordRepeat"
            label="Повторите новый пароль"
            type="password"
          />
        </takeout-layout>
      </template>

      <template v-slot:other></template>

      <template v-slot:submit>
        <div class="login-button-wrapper">
          <takeout-layout takeout="left">
            <button-component :is-disabled="!newPassword || !newPasswordRepeat"
                              class="login-button"
                              @click.stop="onSubmit()"
            >
              Сохранить пароль
              <template #icon-right>
                <img src="@/assets/images/svg/arrow-next.svg" alt="" />
              </template>
            </button-component>
          </takeout-layout>
        </div>
      </template>
    </auth-layout>
  </div>
</template>
<script>
import AuthLayout from "@/modules/auth/AuthLayout.vue";
import InputField from "@/components/ui/InputField.vue";
import TakeoutLayout from "@/components/ui/TakeoutLayout.vue";
import buttonComponent from "@/components/ui/ButtonComponent.vue";
import { authAxios } from "@/common/axios";

export default {
  name: "CreatePass",
  components: {
    buttonComponent,
    TakeoutLayout,
    InputField,
    AuthLayout,
  },
  data() {
    return {
      newPassword: null,
      newPasswordRepeat: null,
      isPasswordsMatch: true,
      error: null,
      validate: {
        matchPass: "",
      },
    };
  },
  methods: {
    async onSubmit() {
      try {
        if (this.newPassword === this.newPasswordRepeat && this.newPassword) {
          await authAxios.put(
            "auth/PasswordRecovery/ResetPassword",
            {
              userId: +this.$route.query?.id,
              token: this.$route.query?.code,
              newPassword: this.newPassword,
            }
          );
          this.validate.matchPass = "";
          this.error = null;
          this.$router.push("/");
        } else {
          this.validate.matchPass = "Пароли должны совпадать";
        }
      } catch (error) {
        console.error("Ошибка запроса:", error);
        this.error = error.response.data.errors[0];
      }
    },
    validateNewPassword() {
      this.newPassword = this.newPassword.replace(/[^a-zA-Z0-9\s.,\-=/&?:%;№#$@()^*|!_]/g, "");
    },
    validateNewPasswordRepeat() {
      this.newPasswordRepeat = this.newPasswordRepeat.replace(/[^a-zA-Z0-9\s.,\-=/&?:%;№#$@()^*|!_]/g, "");
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.form) .login-button-wrapper {
  margin-top: 20px;
}
</style>