import axios from "axios";

export default {
    namespaced: true,
    state: () => ({
        users: null,
    }),
    mutations: {
        setUsers(state, res) {
            state.users = res
        }
    },
    getters: {
        usersForSelect: state => {
            return  state.users?.map(item => {
                return {
                    title: item.fullName,
                    id: item.id
                }
            })
        }
    },
    actions: {
        async getUsers(context) {
            try {
                const response = await axios.get('/User')
                const users = response.data.map(el => {
                    return {
                        id: el.id,
                        login: el.userName,
                        fullName: el.fullName,
                        birthday: el.birthday,
                        position: el.userGroup,
                        groups: el.workshops,
                        phone: el.phoneNumber,
                        email: el.email,
                        extra: el.comment
                    }
                })
                context.commit('setUsers', users)
            }
            catch (e) {
                console.log(e)
            }
        },

    }
}